const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetContactDetail = () => ({
    method: 'POST',
    url: `${getBaseUrl}/contact/get-copy-text`,
    isAuthorize: true,
});

export const apiGetContactMessage = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/contact/get-messages`,
    isAuthorize: true,
});

export const apiGetExportMessage = () => ({
    method: 'POST',
    url: `${getBaseUrl}/contact/export-messages`,
    isAuthorize: true,
});

export const apiPostContactDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/contact/insert-copy-text`,
    isAuthorize: true,
});

export const apiDeleteMessage = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/contact/delete-messages`,
    isAuthorize: true,
});