import { useState } from 'react';
import {
    Breadcrumb,
    Tabs,
} from 'antd';
import BannerImage from './lib/banner-image';
import CompanyImage from './lib/company-image';
import CompanyTestimonial from './lib/company-testimonial';
import TextContent from './lib/text-content';
import CMSLayout from '../../components/layout/cms';

const { TabPane } = Tabs;

const About = (props) => {
    const [loading, setLoading] = useState(false);
    const [reFetch, setReFetch] = useState(true);

    const changeTabs = () => {
        setReFetch(true);
    };
    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>About</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>
                About
            </h1>
            <Tabs className="home__tabs" defaultActiveKey="1" onChange={() => changeTabs()}>
                <TabPane tab="Text Content" key="1">
                    <TextContent
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
                <TabPane tab="Banner Image Slide" key="2">
                    <BannerImage
                        reFetch={reFetch}
                        loading={loading}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
                <TabPane tab="Company Logo Image" key="3">
                    <CompanyImage
                        reFetch={reFetch}
                        loading={loading}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
                <TabPane tab="Company Testimonial" key="4">
                    <CompanyTestimonial
                        reFetch={reFetch}
                        loading={loading}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
            </Tabs>
        </CMSLayout>
    );
};

export default About;