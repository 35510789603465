import { Fragment, useEffect, useState } from 'react';
import {
    Alert,
    Row,
    Col,
    Button,
    Input,
    Modal,
    message,
} from 'antd';
import DataTable from 'react-data-table-component';
import {
    apiGetContactMessage,
    apiGetExportMessage,
    apiDeleteMessage,
} from "../../../api/contact";
import { downloadURI } from '../../../utils/downloadFile';
import { RequestAPI } from '../../../utils/requestApi';

const ContactMessage = (props) => {
    const [tableLoad, setTableLoad] = useState(false);
    const [data, setData] = useState([]);
    const [totalRow, setTotalRow] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
        columns: [],
        order: [],
        start: 0,
        length: 10,
        search: {
            value: ''
        },
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataObj, setDataObj] = useState({
        id: '',
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });
    const {
        reFetch,
        setReFetch,
        setLoading,
    } = props;

    const fetchContactList = async (filterData) => {
        setTableLoad(true);
        try {
            const response = await RequestAPI(apiGetContactMessage(filterData));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                const resDataTotal = response.data.recordsTotal;
                let newFilterObj = Object.assign({}, filterData);
                newFilterObj['columns'] = [];
                columns.map((item) => {
                    let columnData =  {
                        data: "",
                        searchable: "true",
                        search: {
                            value: ""
                        }
                    };
                    if (item.searchable) {
                        columnData.data = item.selector;
                        newFilterObj['columns'].push(columnData);
                    }
                    return columnData;
                });
                setTotalRow(resDataTotal);
                setTableLoad(false);
                setFilter(newFilterObj);
                setData(resData);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setTableLoad(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setTableLoad(false);
        }
    };

    useEffect(() => {
        if (reFetch) {
            fetchContactList(filter);
            setReFetch(false);
            setShowAlert(false);
        };
    // eslint-disable-next-line
    },[reFetch]);

    const handleClick = (record) => {
        if (record) {
            let newDataObj = Object.assign({}, dataObj);
            for(let key in newDataObj) {
                newDataObj[key] = record[key];
            }
            setDataObj(newDataObj);
        };
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Phone',
            selector: 'phone',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Message',
            selector: 'message',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Action',
            button: true,
            cell: (record) => {
                return (
                    <Fragment>
                        <Button type="primary" onClick={() => handleClick(record)}>View</Button>
                    </Fragment>
                )
            },
        },
    ];

    const handlePerRowsChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['length'] = e;

        fetchContactList(newFilterObj);
    };

    const handlePageChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['start'] = (e * newFilterObj.length) - newFilterObj.length;

        fetchContactList(newFilterObj);
    };

    const handleSort = (e, dir) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['order'] = [
            {
                column: e.selector,
                dir: dir,
            }
        ];

        fetchContactList(newFilterObj);
    };

    const handleFilter = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: search,
        };

        fetchContactList(newFilterObj);
    };

    const handleReset = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: '',
        };

        fetchContactList(newFilterObj);
        setSearch('');
    };

    const handleExport = async () => {
        setLoading(true);
        try {
            const response = await RequestAPI(apiGetExportMessage());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                downloadURI(resData, "contact_message");
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        setLoading(true);

        const body = {
            message_id: id,
        };

        try {
            const response = await RequestAPI(apiDeleteMessage(body));
            if (response.status === 200 || response.status === 201) {
                setAlert({
                    type: 'success',
                    title: 'Success',
                    message: response.data.data,
                });
                setShowAlert(true);
                setLoading(false);
                setIsModalVisible(false);
                fetchContactList(filter);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    return (
        <Fragment>
            {
                showAlert && (
                    <Alert 
                        style={{ marginBottom: '16px', textAlign: 'left' }}
                        type={alert.type}
                        message={alert.title}
                        description={alert.message} 
                        showIcon
                    />
                )
            }
            <div className="home__filter">
                <Row align="bottom">
                    <Col span={6}>
                        <div className="form__field" style={{ margin: '0', padding: '0 8px' }}>
                            <div className="form__field-label">Search by</div>
                            <Input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                        <Button className="button--full-width" type="primary" onClick={() => handleFilter()}>
                            Apply Filter
                        </Button>
                    </Col>
                    <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                        <Button className="button--full-width" type="danger" onClick={() => handleReset()}>
                            Reset Filter
                        </Button>
                    </Col>
                    <Col offset={9} span={3} style={{ padding: '0 8px' }}>
                        <Button className="button--full-width" type="primary" onClick={() => handleExport()}>
                            Export
                        </Button>
                    </Col>
                </Row>
            </div>
            <DataTable
                columns={columns}
                data={data}
                progressPending={tableLoad}
                pagination
                paginationServer
                paginationTotalRows={totalRow}
                onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                onChangePage={(e) => handlePageChange(e)}
                onSort={(e, dir) => handleSort(e, dir)}
                sortServer
            />
            <Modal 
                title="Contact Message" 
                visible={isModalVisible} 
                onOk={handleCancel} 
                onCancel={handleCancel}
                footer={[
                    <>
                        <Button className="button" type="danger" onClick={() => handleDelete(dataObj.id)}>
                            Hapus
                        </Button>
                        <Button className="button" type="primary" onClick={() => handleCancel()}>
                            Close
                        </Button>
                    </>
                ]}
            >
                <div className="form__field">
                    <div className="form__field-label">Name</div>
                    <div>
                        {dataObj.name}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Email</div>
                    <div>
                        {dataObj.email}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Phone Number</div>
                    <div>
                        {dataObj.phone}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Message</div>
                    <div>
                        {dataObj.message}
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default ContactMessage;