const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetUserList = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/account/get-admin`,
    isAuthorize: true,
});

export const apiGetUserDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/account/get-admin-detail`,
    isAuthorize: true,
});

export const apiPostUser = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/account/insert-admin`,
    isAuthorize: true,
});