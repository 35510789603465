import { useState } from 'react';
import {
    Breadcrumb,
    Tabs,
} from 'antd';
import WorksCategory from './lib/works';
import BlogsCategory from './lib/blogs';
import CMSLayout from '../../components/layout/cms';

const { TabPane } = Tabs;

const Category = (props) => {
    const [loading, setLoading] = useState(false);
    const [reFetch, setReFetch] = useState(true);

    const changeTabs = () => {
        setReFetch(true);
    };
    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>Master Data - Category</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>
                Category List
            </h1>
            <Tabs className="home__tabs" defaultActiveKey="1" onChange={() => changeTabs()}>
                <TabPane tab="Works Category" key="1">
                    <WorksCategory
                        loading={loading}
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
                <TabPane tab="Blogs Category" key="2">
                    <BlogsCategory
                        loading={loading}
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
            </Tabs>
        </CMSLayout>
    );
};

export default Category;