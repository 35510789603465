import { Fragment, useEffect, useState } from 'react';
import {
    Alert,
    Breadcrumb,
    Row,
    Col,
    Button,
    Input,
    Modal,
    Select,
    Tooltip,
    message,
} from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import DataTable from 'react-data-table-component';
import {
    apiGetSocialMedia,
    apiGetSocialMediaDetail,
    apiPostSocialMedia,
    apiGetWA,
    apiPostWA,
} from "../../api/setting";
import CMSLayout from '../../components/layout/cms';
import {
    validatePhoneNumber,
    validateEmptySpace,
    validateNumber,
} from '../../utils/validations';
import { statusOpt } from '../../utils/variablesObj';
import { RequestAPI } from '../../utils/requestApi';

const { Option } = Select;
const { confirm } = Modal;

const SocialMedia = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [tableLoad, setTableLoad] = useState(false);
    const [getData, setGetData] = useState(true);
    const [data, setData] = useState([]);
    const [totalRow, setTotalRow] = useState(0);
    const [quickChange, setQuickChange] = useState({
        id: '',
        value: '',
        changeData: '',
    });
    const [errorQuick, setErrorQuick] = useState();
    const [dataField, setDataField] = useState('');
    const [errorField, setErrorField] = useState('');
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
        columns: [],
        order: [],
        start: 0,
        length: 10,
        search: {
            value: ''
        },
    });
    const [id, setId] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataObj, setDataObj] = useState({
        name: '',
        link: '',
        description: '',
        logo: '',
        sequence: '',
        status: '',
    });
    const [sliderImg, setSliderImg] = useState('');
    const [previewImg, setPreviewImg] = useState('');
    const [error, setError] = useState({
        name: '',
        link: '',
        description: '',
        logo: '',
        sequence: '',
        status: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });

    const fetchSocialMediaList = async (filterData) => {
        setTableLoad(true);
        try {
            const response = await RequestAPI(apiGetSocialMedia(filterData));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                const resDataTotal = response.data.recordsTotal;
                let newFilterObj = Object.assign({}, filterData);
                newFilterObj['columns'] = [];
                columns.map((item) => {
                    let columnData =  {
                        data: "",
                        searchable: "true",
                        search: {
                            value: ""
                        }
                    };
                    if (item.searchable) {
                        columnData.data = item.selector;
                        newFilterObj['columns'].push(columnData);
                    }
                    return columnData;
                });
                setTotalRow(resDataTotal);
                setTableLoad(false);
                setFilter(newFilterObj);
                setData(resData);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setTableLoad(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setTableLoad(false);
        }
    };

    const fetchSocialMediaDetail = async (id) => {
        setLoading(true);
        setLoadingDetail(true);
        try {
            let formData = new FormData();
            formData.append('id', id);
            const response = await RequestAPI(apiGetSocialMediaDetail(formData));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newDataObj = Object.assign({}, dataObj);
                for(let key in newDataObj) {
                    if (key === "logo") {
                        setSliderImg(resData['logo']);
                        setPreviewImg(resData['logo']);
                    } else {
                        newDataObj[key] = resData[key];
                    }
                }
                setDataObj(newDataObj);
                setLoading(false);
                setLoadingDetail(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
                setLoadingDetail(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
            setLoadingDetail(false);
        }
    };

    const fetchWADetail = async () => {
        setLoading(true);
        try {
            const response = await RequestAPI(apiGetWA());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                setDataField(resData.description);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (getData) {
            fetchSocialMediaList(filter);
            fetchWADetail();
            setGetData(false);
        };
    // eslint-disable-next-line
    },[getData]);

    const handleDelete = (record) => {
        confirm({
            title: "Are you sure want to delete this social media?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let newDataObj = Object.assign({}, dataObj);
                for(let key in newDataObj) {
                    if (key === "logo") {
                        setSliderImg(record['logo']);
                        setPreviewImg(record['logo']);
                    }
                    newDataObj[key] = record[key];
                }
                newDataObj['status'] = 'Trash';
                handleDataSocialMedia(newDataObj, record.id);
            },
            onCancel() { return }
        });
    };

    const handleClick = (record) => {
        if (record) {
            fetchSocialMediaDetail(record.id);
            setId(record.id);
        };
        resetForm();
        setIsModalVisible(true);
    };

    const handleQuickChange = (e, inputName) => {
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newQuickObj = Object.assign({}, quickChange);

        newQuickObj[name] = value;

        setQuickChange(newQuickObj);
        setErrorQuick('');
    };

    const handleQuickSubmit = async () => {
        setTableLoad(true);
        try {
            let formData = new FormData();
            formData.append('id', quickChange.id);
            formData.append(quickChange.changeData, quickChange.value);
            const response = await RequestAPI(apiPostSocialMedia(formData));
            if (response.status === 200 || response.status === 201) {
                message.success("Success update data");
                setQuickChange({
                    id: '',
                    value: '',
                    changeData: '',
                });
                fetchSocialMediaList(filter);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setTableLoad(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setTableLoad(false);
        }
    };

    const handleQuickData = (e) => {
        e.preventDefault();
        let isError = false;
        let errMsg = '';
        for (let key in quickChange) {
            if (quickChange['changeData'] === "sequence" && !validateNumber(quickChange['value'])) {
                errMsg = "Numeric input only";
                isError = true;
            }
            
            if (!quickChange[key] || validateEmptySpace(quickChange[key])) {
                errMsg = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleQuickSubmit();
        } else {
            setErrorQuick(errMsg);
        }
    };

    const resetForm = () => {
        setQuickChange({
            id: '',
            value: '',
            changeData: '',
        });
        setErrorQuick('');
    };

    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Link Url',
            selector: 'link',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Sequence',
            selector: 'sequence',
            sortable: true,
            searchable: true,
            cell: (record) => {
                if (record.id === quickChange.id && quickChange.changeData === "sequence") {
                    return (
                        <div style={{ position: 'relative' }}>
                            <form onSubmit={(e) => handleQuickData(e)}>
                                <Input
                                    name="value"
                                    value={quickChange.value}
                                    onChange={(e) => handleQuickChange(e)}
                                />
                                <div
                                    className="form__check"
                                    onClick={(e) => handleQuickData(e)}
                                >
                                    <CheckOutlined />
                                </div>
                                <div
                                    className="form__close"
                                    onClick={() => resetForm()}
                                >
                                    <CloseOutlined />
                                </div>
                                <div className="form__field--error">
                                    {errorQuick}
                                </div>
                            </form>
                        </div>
                    )
                } else {
                    return (
                        <Tooltip placement="right" title={'double click to change'}>
                            <div 
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onDoubleClick={() => {
                                    let newQuickObj = Object.assign({}, quickChange);
                                    newQuickObj.id = record.id;
                                    newQuickObj.value = record.sequence;
                                    newQuickObj.changeData = "sequence";
                                    setQuickChange(newQuickObj);
                                }}>
                                {record.sequence}
                            </div>
                        </Tooltip>
                    )
                }
            },
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            searchable: true,
            cell: (record) => {
                if (record.id === quickChange.id && quickChange.changeData === "status") {
                    return (
                        <div style={{ position: 'relative' }}>
                            <form onSubmit={(e) => handleQuickData(e)}>
                                <Select
                                    name="value"
                                    value={quickChange.value}
                                    style={{ width: '150px' }}
                                    onChange={(e) => handleQuickChange(e, 'value')}
                                >
                                    {
                                        statusOpt.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.value}>{item.label}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                <div
                                    className="form__check"
                                    onClick={(e) => handleQuickData(e)}
                                >
                                    <CheckOutlined />
                                </div>
                                <div
                                    className="form__close"
                                    onClick={() => resetForm()}
                                >
                                    <CloseOutlined />
                                </div>
                                <div className="form__field--error">
                                    {errorQuick}
                                </div>
                            </form>
                        </div>
                    )
                } else {
                    return (
                        <Tooltip placement="right" title={'double click to change'}>
                            <div 
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onDoubleClick={() => {
                                    let newQuickObj = Object.assign({}, quickChange);
                                    newQuickObj.id = record.id;
                                    newQuickObj.value = record.status;
                                    newQuickObj.changeData = "status";
                                    setQuickChange(newQuickObj);
                                }}>
                                {record.status}
                            </div>
                        </Tooltip>
                    )
                }
            },
        },
        {
            name: 'Action',
            width: '200px',
            button: true,
            cell: (record) => {
                return (
                    <Fragment>
                        <Button type="primary" onClick={() => handleClick(record)}>Edit</Button>
                        <Button type="danger" style={{ marginLeft: '8px' }} onClick={() => handleDelete(record)}>Delete</Button>
                    </Fragment>
                )
            },
        },
    ];

    const handlePerRowsChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['length'] = e;

        fetchSocialMediaList(newFilterObj);
    };

    const handlePageChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['start'] = (e * newFilterObj.length) - newFilterObj.length;

        fetchSocialMediaList(newFilterObj);
    };

    const handleSort = (e, dir) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['order'] = [
            {
                column: e.selector,
                dir: dir,
            }
        ];

        fetchSocialMediaList(newFilterObj);
    };

    const handleFilter = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: search,
        };

        fetchSocialMediaList(newFilterObj);
    };

    const handleReset = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: '',
        };

        fetchSocialMediaList(newFilterObj);
        setSearch('');
    };

    const handleChange = (e, inputName) => {
        setShowAlert(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, dataObj);
        let newErrorObj = Object.assign({}, error);

        if (name === "logo") {
            const file = e.target.files[0];
            
            let reader = new FileReader();
            // eslint-disable-next-line
            let url = reader.readAsDataURL(file);

            reader.onloadend = (e) => {
                setPreviewImg([reader.result]);
            }
            setSliderImg(file);
        }

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setDataObj(newDataObj);
        setError(newErrorObj);
    };
    
    const handleDataSocialMedia = async (form, recordId) => {
        setLoading(true);
        try {
            let formData = new FormData();
            let dataForm = form ? form : dataObj;
            for (let key in dataForm) {
                if (key === "logo" && dataForm[key]) {
                    formData.append(key, sliderImg);
                } else {
                    formData.append(key, dataForm[key]);
                }
            };
            if (id || recordId) {
                formData.append('id', id || recordId);
            }
            const response = await RequestAPI(apiPostSocialMedia(formData));
            if (response.status === 200 || response.status === 201) {
                if (dataForm['status'] === "Trash") {
                    message.success("Success delete social media");
                } else {
                    setAlert({
                        type: 'success',
                        title: 'Success',
                        message: id ? 'Success update social media' : 'Success create social media',
                    });
                    setShowAlert(true);
                }
                setLoading(false);
                fetchSocialMediaList(filter);
                if (!id) {
                    handleCancel();
                }
            } else {
                const errMsg = response.error.message;
                if (form && form['status'] === "Trash") {
                    message.error(errMsg);
                } else {
                    setAlert({
                        type: 'error',
                        title: 'Error Social Media',
                        message: errMsg,
                    });
                    setShowAlert(true);
                }
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            if (form && form['status'] === "Trash") {
                message.error(errMsg);
            } else {
                setAlert({
                    type: 'error',
                    title: 'Error Social Media',
                    message: errMsg,
                });
                setShowAlert(true);
            }
            setLoading(false);
        }
    };

    const handleOk = (e) => {
        e.preventDefault();
        let isError = false;
        let newErrorObj = Object.assign({}, error);
        for (let key in dataObj) {
            if (key === "logo" && !sliderImg) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            } else if (key === "sequence" && !validateNumber(dataObj[key])) {
                newErrorObj[key] = "Numeric input only";
                isError = true;
            }

            if ((!dataObj[key] || validateEmptySpace(dataObj[key])) && key !== "logo") {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleDataSocialMedia();
        } else {
            setError(newErrorObj);
            setAlert({
                type: 'error',
                title: 'Error Social Media',
                message: "There's an error on input field",
            });
            setShowAlert(true);
        }
    };

    const handleCancel = () => {
        let newDataObj = Object.assign({}, dataObj);
        let newErrorObj = Object.assign({}, error);
        for (let key in newDataObj) {
            newDataObj[key] = '';
            newErrorObj[key] = '';
        }

        setId('');
        setSliderImg('');
        setPreviewImg('');
        setDataObj(newDataObj);
        setError(newErrorObj);
        setShowAlert(false);
        setIsModalVisible(false);
    };

    const handleSubmitWA = async () => {
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('link', dataField);
            formData.append('description', dataField);
            const response = await RequestAPI(apiPostWA(formData));
            if (response.status === 200 || response.status === 201) {
                message.success('Success update WA number');
                fetchWADetail();
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleWa = () => {
        if (!dataField || validateEmptySpace(dataField)) {
            setErrorField("Field is empty");
        } else {
            if (!validateNumber(dataField)) {
                setErrorField("Numeric input only");
            } else if (!validatePhoneNumber(dataField)) {
                setErrorField("Whatsapp number format must be 08xxx");
            } else {
                handleSubmitWA();
            }
        }
    };

    const handleResetWa = () => {
        setErrorField('');
        fetchWADetail();
    };

    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        Setting - Social Media
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>
                Social Media
            </h1>
            <div className="cms__container">
                <div className="form__field">
                    <div className="form__field-label">Whatsapp Number</div>
                    <Input
                        name="dataField"
                        value={dataField}
                        onChange={(e) => setDataField(e.target.value)}
                    />
                    <div className="form__notes">
                        Notes - Whatsapp Number format must be 08xxx
                    </div>
                    <div className="form__field--error">
                        {errorField}
                    </div>
                </div>
                <div className="form__field">
                    <Row>
                        <Col span={2}>
                            <Button className="button--full-width" type="primary" onClick={() => handleWa()}>
                                Update
                            </Button>
                        </Col>
                        <Col span={2} style={{ marginLeft: '8px' }}>
                            <Button className="button--full-width" type="danger" onClick={() => handleResetWa()}>
                                Reset Form
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="home__filter">
                    <Row align="bottom">
                        <Col span={6}>
                            <div className="form__field" style={{ margin: '0', padding: '0 8px' }}>
                                <div className="form__field-label">Search by</div>
                                <Input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                            <Button className="button--full-width" type="primary" onClick={() => handleFilter()}>
                                Apply Filter
                            </Button>
                        </Col>
                        <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                            <Button className="button--full-width" type="danger" onClick={() => handleReset()}>
                                Reset Filter
                            </Button>
                        </Col>
                        <Col offset={9} span={3} style={{ padding: '0 8px' }}>
                            <Button className="button--full-width" type="primary" onClick={() => handleClick()}>
                                Add New +
                            </Button>
                        </Col>
                    </Row>
                </div>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={tableLoad}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRow}
                    onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                    onChangePage={(e) => handlePageChange(e)}
                    onSort={(e, dir) => handleSort(e, dir)}
                    sortServer
                />
                <Modal confirmLoading={loading} title={loadingDetail ? "Loading Data..." : id ? `${dataObj.name}` : "Add New Social Media"} visible={isModalVisible} onOk={handleOk} okText={"Save"} onCancel={handleCancel}>
                    {
                        showAlert && (
                            <Alert 
                                style={{ marginBottom: '16px', textAlign: 'left' }}
                                type={alert.type}
                                message={alert.title}
                                description={alert.message} 
                                showIcon
                            />
                        )
                    }
                    <div className="form__field">
                        <div className="form__field-label">Status</div>
                        <Select 
                            value={dataObj.status}
                            name="status"
                            onChange={(e) => handleChange(e, 'status')}
                        >
                            {
                                statusOpt.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.value}>{item.label}</Option>
                                    )
                                })
                            }
                        </Select>
                        <div className="form__field--error">
                            {error.status}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Name</div>
                        <Input
                            value={dataObj.name}
                            name="name"
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.name}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Sequence</div>
                        <Input
                            value={dataObj.sequence}
                            name="sequence"
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.sequence}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Link</div>
                        <Input
                            value={dataObj.link}
                            name="link"
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.link}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Description</div>
                        <Input
                            value={dataObj.description}
                            name="description"
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.description}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Logo File</div>
                        <div className="form__field-upload">
                            <input
                                type="file"
                                name="logo"
                                value={dataObj.logo}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className="form__field--info">
                            Notes
                            <div>
                                - Ratio 1:1
                            </div>
                            <div>
                                - Max dimension 24 x 24
                            </div>
                        </div>
                        <div className="form__field--error">
                            {error.logo}
                        </div>
                        {
                            previewImg && (
                                <div className="form__field--img-preview">
                                    <div>Image Preview:</div>
                                    <img src={previewImg} alt={"img-preview"}/>
                                </div>
                            )
                        }
                    </div>
                </Modal>
            </div>
        </CMSLayout>
    );
};

export default SocialMedia;