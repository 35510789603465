const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetAboutDetail = () => ({
    method: 'POST',
    url: `${getBaseUrl}/about/get`,
    isAuthorize: true,
});

export const apiPostAboutDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/about/manage-about`,
    isAuthorize: true,
});

export const apiGetAboutImage = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/about/get-about-image`,
    isAuthorize: true,
});

export const apiPostAboutImage = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/about/manage-about-image`,
    isAuthorize: true,
});

export const apiGetAboutLogo = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/about/get-about-client`,
    isAuthorize: true,
});

export const apiPostAboutLogo = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/about/manage-about-client`,
    isAuthorize: true,
});

export const apiGetAboutTesti = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/about/get-about-testimonial`,
    isAuthorize: true,
});

export const apiPostAboutTesti = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/about/manage-about-testimonial`,
    isAuthorize: true,
});