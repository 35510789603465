import { useEffect, useState, useRef } from 'react';
import {
    Alert,
    Button,
    Input,
    Spin,
    message,
} from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import {
    apiLogin,
    apiGetAccessMenu,
} from "../../api/auth";
import Logo from '../../assets/images/logo-color.svg';
import {
    validateEmail,
} from '../../utils/validations';
import { RequestAPI } from '../../utils/requestApi';

const Login = () => {
    const recaptchaRef = useRef();
    const [loading, setLoading] = useState(false);
    const [checkError, setCheckError] = useState(true);
    const [data, setData] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState({
        email: '',
        password: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });

    useEffect(() => {
        if (checkError) {
          const urlParams = new URLSearchParams(window.location.search);
          const errorMsg = urlParams.get('error');
          if (errorMsg) {
            setAlert({
              type: 'error',
              title: 'Error Login',
              message: errorMsg,
            });
            setShowAlert(true);
          }
        }
    
        setCheckError(false);
    // eslint-disable-next-line
    }, [checkError]);

    const fetchAccessMenu = async () => {
        setLoading(true);
        try {
            const response = await RequestAPI(apiGetAccessMenu());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let accessArr = [];
                let redirectURL = '';
                resData.map((item) => {
                    if (item.status === "Active") {
                        let accessObj = {
                            name: item.name,
                            link: item.link,
                        };
                        if (item.child_menu.length > 0) {
                            accessObj.link = item.child_menu[0].link;
                            if (!redirectURL) {
                                redirectURL = item.child_menu[0].link;
                            }
                            accessArr.splice(item.sequence, 0, accessObj);
                            return accessObj;
                        } else {
                            accessArr.splice(item.sequence, 0, accessObj);
                        }
                        if (!redirectURL) {
                            redirectURL = item.link;
                            return redirectURL;
                        }
                        return accessArr;
                    }
                    return item;
                });

                const AccessDataStr = JSON.stringify(accessArr);
                localStorage.setItem('access', AccessDataStr);
                localStorage.setItem('isLoggedIn', true);
                window.location.href = process.env.REACT_APP_BASE_URL + redirectURL;
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        setShowAlert(false);
        const name = e.target.name;
        const value = e.target.value;
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setData(newDataObj);
        setError(newErrorObj);
    };

    const handleLogin = async (token) => {
        try {
            let newDataObj = Object.assign({}, data);
            newDataObj['g-recaptcha-response'] = token;
            const response = await RequestAPI(apiLogin(newDataObj));
            if (response.status === 200 || response.status === 201) {
                fetchAccessMenu();
            } else {
                const errMsg = response.error.message;
                setAlert({
                    type: 'error',
                    title: 'Error Login',
                    message: errMsg,
                });
                setShowAlert(true);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlert({
                type: 'error',
                title: 'Error Login',
                message: errMsg,
            });
            setShowAlert(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isError = false;
        const token = await recaptchaRef.current.executeAsync();
        let newErrorObj = Object.assign({}, error);
        for (let key in data) {
            if (key === "email" && !validateEmail(data[key])) {
                newErrorObj[key] = "Wrong email format";
                isError = true;
            }

            if (!data[key]) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleLogin(token);
        } else {
            setError(newErrorObj);
        }
    };

    return (
        <div className="login__container">
            {
                loading && (
                    <div className="cms-layout__loading">
                        <div className="cms-layout__spinner">
                            <Spin/>
                        </div>
                    </div>
                )
            }
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_GAPTCHA_SITE_KEY}
            />
            <div className="login__wrapper">
                <div className="login__logo">
                    <img src={Logo} alt={"logo"}/>
                </div>
                {
                    showAlert && (
                        <Alert 
                            style={{ margin: '16px 0', textAlign: 'left' }}
                            type={alert.type}
                            message={alert.title}
                            description={alert.message} 
                            showIcon
                        />
                    )
                }
                <div className="login__form">
                    <h2>
                        Login
                    </h2>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="form__field">
                            <div className="form__field-label">Email</div>
                            <Input
                                name="email"
                                value={data.email}
                                onChange={(e) => handleChange(e)}
                            />
                            <div className="form__field--error">
                                {error.email}
                            </div>
                        </div>
                        <div className="form__field">
                            <div className="form__field-label">Password</div>
                            <Input.Password
                                name="password"
                                value={data.password}
                                onChange={(e) => handleChange(e)}
                            />
                            <div className="form__field--error">
                                {error.password}
                            </div>
                        </div>
                        <div className="login__button form__button">
                            <Button className="button--full-width" type="primary" size="large" htmlType="submit">
                                Sign In
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;