import { Fragment, useEffect, useState } from 'react';
import {
    Alert,
    Row,
    Col,
    Button,
    Input,
    Select,
    DatePicker,
    message,
    Modal,
} from 'antd';
import { SketchPicker } from 'react-color';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import {
    apiPostWorksCategories,
} from "../../../api/categories";
import {
    apiGetWorksCategories,
    apiPostWorksDetail,
} from "../../../api/works";
import { customAdapter } from '../../../utils/editorUploadAdapter';
import {
    validateEmptySpace,
} from '../../../utils/validations';
import { statusOpt } from '../../../utils/variablesObj';
import { RequestAPI } from '../../../utils/requestApi';

const { TextArea } = Input;
const { Option } = Select;

const WorksContent = (props) => {
    const [data, setData] = useState({
        status: '',
        work_list_title: '',
        work_list_description: '',
        images: '',
        images_hover: '',
        back_title: 'Back to works',
        sub_title: '',
        title: '',
        title_color: '',
        categories: [],
        date: '',
        big_paragraf: '',
        content: '',
        browse_other_text: 'Browse other works',
        seo_meta_title: '',
        seo_meta_description: '',
    });
    const [sliderImg, setSliderImg] = useState('');
    const [previewImg, setPreviewImg] = useState('');
    const [sliderHoverImg, setSliderHoverImg] = useState('');
    const [previewHoverImg, setPreviewHoverImg] = useState('');
    const [error, setError] = useState({
        status: '',
        work_list_title: '',
        work_list_description: '',
        images: '',
        images_hover: '',
        back_title: '',
        sub_title: '',
        title: '',
        title_color: '',
        categories: [],
        date: '',
        big_paragraf: '',
        content: '',
        browse_other_text: '',
        seo_meta_title: '',
        seo_meta_description: '',
    });
    const [loadingOption, setLoadingOption] = useState(false);
    const [optionData, setOptionData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataObj, setDataObj] = useState({
        name: '',
        status: '',
    });
    const [errorObj, setErrorObj] = useState({
        name: '',
        status: '',
    });
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModal, setAlertModal] = useState({
        type: '',
        title: '',
        message: '',
    });
    const filter = {
        columns: [],
        order: [],
        start: 0,
        length: 9999,
        search: {
            value: ''
        },
    };
    const {
        loading,
        paramsId,
        reFetch,
        dataDetail,
        setReFetch,
        setLoading,
        fetchWorksDetail,
    } = props;

    const fetchCategoryList = async () => {
        setLoadingOption(true);
        try {
            const response = await RequestAPI(apiGetWorksCategories(filter));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let optionArr = [];
                resData.map((item) => {
                    if (item.status === "Active") {
                        let optionObj = {
                            value: item.id,
                            label: item.name,
                        };
                        optionArr.push(optionObj);
                        return optionObj;
                    } else {
                        return item;
                    }
                });
                setOptionData(optionArr);
                setLoadingOption(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoadingOption(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoadingOption(false);
        }
    };

    useEffect(() => {
        if (reFetch) {
            if (paramsId) {
                let newDataDetailProps = Object.assign({}, dataDetail);
                let newDataObj = Object.assign({}, data);
                for (let key in newDataObj) {
                    if (key === "images") {
                        newDataObj[key] = "";
                        setSliderImg(newDataDetailProps['images']);
                        setPreviewImg(newDataDetailProps['images']);
                    } else if (key === "images_hover") {
                        newDataObj[key] = "";
                        setSliderHoverImg(newDataDetailProps['images_hover']);
                        setPreviewHoverImg(newDataDetailProps['images_hover']);
                    } else if (key === "date") {
                        let date = moment(newDataDetailProps[key]);
                        newDataObj[key] = date;
                    } else if (key === "categories") {
                        let categoriesArr = [];
                        const categories = newDataDetailProps.category_ids.split(",");
                        categories.map((item) => {
                            let valueInt = parseInt(item);
                            categoriesArr.push(valueInt);
                            return valueInt;
                        });
                        newDataObj[key] = categoriesArr;
                    } else {
                        newDataObj[key] = newDataDetailProps[key];
                    }
                }
                setData(newDataObj);
            }
            fetchCategoryList();
            setReFetch(false);
        }
    // eslint-disable-next-line
    }, [reFetch, paramsId]);

    const handleChange = (e, inputName) => {
        setShowAlert(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);

        if (name === "images" || name === "images_hover") {
            const file = e.target.files[0];
            
            let reader = new FileReader();
            // eslint-disable-next-line
            let url = reader.readAsDataURL(file);

            if (name === "images") {
                reader.onloadend = (e) => {
                    setPreviewImg([reader.result]);
                }
                setSliderImg(file);
            } else {
                reader.onloadend = (e) => {
                    setPreviewHoverImg([reader.result]);
                }
                setSliderHoverImg(file);
            }
        }

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setData(newDataObj);
        setError(newErrorObj);
    };

    const handleWorksDetail = async () => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            let formData = new FormData();
            for (let key in data) {
                if (key === "images" && data[key]) {
                    formData.append(key, sliderImg);
                } else if (key === "images_hover" && data[key]) {
                    formData.append(key, sliderHoverImg);
                } else if (key === "date") {
                    const dateFormat = moment(data[key]).format("YYYY-MM-DD");
                    formData.append(key, dateFormat);
                } else {
                    formData.append(key, data[key]);
                }
            };
            if (paramsId) {
                formData.append('string_id', paramsId);
            }
            const response = await RequestAPI(apiPostWorksDetail(formData));
            if (response.status === 200 || response.status === 201) {
                setAlert({
                    type: 'success',
                    title: 'Success',
                    message: paramsId ? 'Success update works' : 'Success create works',
                });
                setShowAlert(true);
                setLoading(false);
                if (paramsId) {
                    fetchWorksDetail(paramsId);
                    setReFetch(true);
                } else {
                    setTimeout(() => {
                        window.location.href = process.env.REACT_APP_BASE_URL + "/works";
                    }, 1000);
                }
            } else {
                const errMsg = response.error.message;
                setAlert({
                    type: 'error',
                    title: 'Error Works Detail',
                    message: errMsg,
                });
                setShowAlert(true);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlert({
                type: 'error',
                title: 'Error Works Detail',
                message: errMsg,
            });
            setShowAlert(true);
            setLoading(false);
        }
    };

    const handleSubmit = () => {
        let isError = false;
        let newErrorObj = Object.assign({}, error);
        for (let key in data) {
            if (key === "images" && !sliderImg) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            } else if (key === "categories" && data[key].length === 0) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }

            if ((!data[key] || validateEmptySpace(data[key])) && key !== "images" && key !== "images_hover") {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleWorksDetail();
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            setError(newErrorObj);
            setAlert({
                type: 'error',
                title: 'Error Works Detail',
                message: "There's an error on input field",
            });
            setShowAlert(true);
        }
    };

    const handleReset = () => {
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);
        for (let k in newDataObj) {
            newErrorObj[k] = '';
        }
        
        if (paramsId) {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            fetchWorksDetail(paramsId);
        } else {
            window.location.href = process.env.REACT_APP_BASE_URL + "/works";
        }
        setError(newErrorObj);
        setShowAlert(false);
    };

    const handleChangeModal = (e, inputName) => {
        setShowAlertModal(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, dataObj);
        let newErrorObj = Object.assign({}, errorObj);

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setDataObj(newDataObj);
        setErrorObj(newErrorObj);
    };

    const handleDataWorksCategory = async () => {
        setLoading(true);
        try {
            let formData = new FormData();
            for (let key in dataObj) {
                formData.append(key, dataObj[key]);
            };
            const response = await RequestAPI(apiPostWorksCategories(formData));
            if (response.status === 200 || response.status === 201) {
                setAlertModal({
                    type: 'success',
                    title: 'Success',
                    message: 'Success create category',
                });
                setShowAlertModal(true);
                setLoading(false);
                fetchCategoryList();
                setTimeout(() => {
                    setIsModalVisible(false);
                }, 500);
            } else {
                const errMsg = response.error.message;
                setAlertModal({
                    type: 'error',
                    title: 'Error Works Category',
                    message: errMsg,
                });
                setShowAlertModal(true);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlertModal({
                type: 'error',
                title: 'Error Works Category',
                message: errMsg,
            });
            setShowAlertModal(true);
            setLoading(false);
        }
    };

    const handleOk = (e) => {
        e.preventDefault();
        let isError = false;
        let newErrorObj = Object.assign({}, errorObj);
        for (let key in dataObj) {
            if (!dataObj[key] || validateEmptySpace(dataObj[key])) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleDataWorksCategory();
        } else {
            setErrorObj(newErrorObj);
            setAlertModal({
                type: 'error',
                title: 'Error Works Category',
                message: "There's an error on input field",
            });
            setShowAlertModal(true);
        }
    };

    const handleCancel = () => {
        let newDataObj = Object.assign({}, dataObj);
        let newErrorObj = Object.assign({}, errorObj);
        for (let key in newDataObj) {
            newDataObj[key] = '';
            newErrorObj[key] = '';
        }

        setDataObj(newDataObj);
        setErrorObj(newErrorObj);
        setShowAlertModal(false);
        setIsModalVisible(false);
    };

    const customConfig = {
        extraPlugins: [ customAdapter ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'imageUpload',
            'blockQuote',
            '|',
            'undo',
            'redo'
          ]
        }
    };

    return (
        <Fragment>
            <div className="form__wrapper">
                {
                    showAlert && (
                        <Alert 
                            style={{ marginBottom: '16px', textAlign: 'left' }}
                            type={alert.type}
                            message={alert.title}
                            description={alert.message} 
                            showIcon
                        />
                    )
                }
                <div className="form__field">
                    <div className="form__field-label">Status</div>
                    <Select 
                        value={data.status}
                        name="status"
                        onChange={(e) => handleChange(e, 'status')}
                    >
                        {
                            statusOpt.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>{item.label}</Option>
                                )
                            })
                        }
                    </Select>
                    <div className="form__field--error">
                        {error.status}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Work List Title</div>
                    <Input
                        value={data.work_list_title}
                        name="work_list_title"
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.work_list_title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Work List Description</div>
                    <Input
                        value={data.work_list_description}
                        name="work_list_description"
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.work_list_description}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Works Thumbnail Image File</div>
                    <div className="form__field-upload">
                        <input
                            type="file"
                            name="images"
                            value={data.images}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="form__field--info">
                        Notes
                        <div>
                            - Ratio 1:1
                        </div>
                        <div>
                            - Max dimension 320 x 320
                        </div>
                    </div>
                    <div className="form__field--error">
                        {error.images}
                    </div>
                    {
                        previewImg && (
                            <div className="form__field--img-preview">
                                <div>Image Preview:</div>
                                <img src={previewImg} alt={"img-preview"}/>
                            </div>
                        )
                    }
                </div>
                <div className="form__field">
                    <div className="form__field-label">Works Thumbnail Hover Image File</div>
                    <div className="form__field-upload">
                        <input
                            type="file"
                            name="images_hover"
                            value={data.images_hover}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="form__field--info">
                        Notes
                        <div>
                            - Ratio 1:1
                        </div>
                        <div>
                            - Max dimension 320 x 320
                        </div>
                    </div>
                    <div className="form__field--error">
                        {error.images_hover}
                    </div>
                    {
                        previewHoverImg && (
                            <div className="form__field--img-preview">
                                <div>Image Preview:</div>
                                <img src={previewHoverImg} alt={"img-preview"}/>
                            </div>
                        )
                    }
                </div>
                <hr/>
                <div className="form__field">
                    <div className="form__field-label">Back Text</div>
                    <Input
                        value={data.back_title}
                        name="back_title"
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.back_title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Sub Title</div>
                    <Input
                        value={data.sub_title}
                        name="sub_title"
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.sub_title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Title</div>
                    <Input
                        value={data.title}
                        name="title"
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Title Color</div>
                    <div style={{ marginBottom: '8px' }}>
                        <SketchPicker
                            name="title_color"
                            color={data.title_color || ''}
                            onChange={(e) => handleChange(e.hex, 'title_color')}
                        />
                    </div>
                    <Input disabled value={data.title_color} />
                    <div className="form__field--error">
                        {error.title_color}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Categories</div>
                    <div style={{ position: 'relative' }}>
                        <Select 
                            mode="multiple"
                            loading={loadingOption}
                            value={data.categories}
                            name="categories"
                            onChange={(e) => handleChange(e, 'categories')}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                optionData.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.value}>{item.label}</Option>
                                    )
                                })
                            }
                        </Select>
                        <div className="form__field--add" onClick={() => setIsModalVisible(true)}>
                            + Add new category
                        </div>
                    </div>
                    <div className="form__field--error">
                        {error.categories}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Date</div>
                    <DatePicker 
                        style={{ width: '100%' }}
                        format={'DD-MMM-YYYY'}
                        value={data.date}
                        name="date"
                        onChange={(e) => handleChange(e, 'date')}
                    />
                    <div className="form__field--error">
                        {error.date}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Big Paragraf</div>
                    <TextArea 
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        value={data.big_paragraf}
                        name="big_paragraf"
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.big_paragraf}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Content</div>
                    <div className="form__twice-width">
                        <CKEditor
                            editor={ClassicEditor}
                            data={data.content}
                            config={customConfig}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                if (!reFetch) {
                                    handleChange(data, 'content');
                                }
                            } }
                        />
                    </div>
                    <div className="form__field--error">
                        {error.content}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Browse Other Text</div>
                    <Input
                        value={data.browse_other_text}
                        name="browse_other_text"
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.browse_other_text}
                    </div>
                </div>
                <hr/>
                <div className="form__field">
                    <div className="form__field-label">SEO - Meta Title</div>
                    <Input
                        value={data.seo_meta_title}
                        name="seo_meta_title"
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.seo_meta_title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">SEO - Meta Description</div>
                    <Input
                        value={data.seo_meta_description}
                        name="seo_meta_description"
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.seo_meta_description}
                    </div>
                </div>
                <div className="form__button">
                    <Row>
                        <Col span={6}>
                            <Button className="button--full-width" type="primary" onClick={() => handleSubmit()}>
                                {
                                    paramsId ? (
                                        "Update"
                                    ) : (
                                        "Create"
                                    )
                                }
                            </Button>
                        </Col>
                        <Col span={6} style={{ marginLeft: '8px' }}>
                            <Button className="button--full-width" type="danger" onClick={() => handleReset()}>
                                {
                                    paramsId ? (
                                        "Reset Form"
                                    ) : (
                                        "Cancel"
                                    )
                                }
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal confirmLoading={loading} title={"Add New Works Category"} visible={isModalVisible} onOk={handleOk} okText={"Save"} onCancel={handleCancel}>
                {
                    showAlertModal && (
                        <Alert 
                            style={{ marginBottom: '16px', textAlign: 'left' }}
                            type={alertModal.type}
                            message={alertModal.title}
                            description={alertModal.message} 
                            showIcon
                        />
                    )
                }
                <div className="form__field">
                    <div className="form__field-label">Status</div>
                    <Select 
                        value={dataObj.status}
                        name="status"
                        onChange={(e) => handleChangeModal(e, 'status')}
                    >
                        {
                            statusOpt.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>{item.label}</Option>
                                )
                            })
                        }
                    </Select>
                    <div className="form__field--error">
                        {errorObj.status}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Works Category Name</div>
                    <Input
                        value={dataObj.name}
                        name="name"
                        onChange={(e) => handleChangeModal(e)}
                    />
                    <div className="form__field--error">
                        {errorObj.name}
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default WorksContent;