import { Fragment, useEffect, useState } from 'react';
import {
    Alert,
    Row,
    Col,
    Button,
    Input,
    message,
} from 'antd';
import {
    apiGetHomeSliderSEO,
    apiPostHomeSliderSEO,
} from "../../../api/home";
import {
    validateEmptySpace,
} from '../../../utils/validations';
import { RequestAPI } from '../../../utils/requestApi';

const SEOSlider = (props) => {
    const [data, setData] = useState({
        seo_meta_description: '',
        seo_meta_title: '',
    });
    const [error, setError] = useState({
        seo_meta_description: '',
        seo_meta_title: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });
    const {
        reFetch,
        setReFetch,
        setLoading,
    } = props;

    const fetchHomeSEO = async () => {
        setLoading(true);
        try {
            const response = await RequestAPI(apiGetHomeSliderSEO());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newDataObj = Object.assign({}, data);
                for (let key in newDataObj) {
                    newDataObj[key] = resData[key];
                };
                setData(newDataObj);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleReset = () => {
        let newErrorObj = Object.assign({}, error);
        for (let k in newErrorObj) {
            newErrorObj[k] = '';
        }
        
        fetchHomeSEO();
        setError(newErrorObj);
        setShowAlert(false);
    };

    useEffect(() => {
        if (reFetch) {
            setShowAlert(false);
            setReFetch(false);
            handleReset();
        }
    // eslint-disable-next-line
    },[reFetch]);

    const handleChange = (e, inputName) => {
        setShowAlert(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setData(newDataObj);
        setError(newErrorObj);
    };

    const handleDataHomeSEO = async () => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            };
            const response = await RequestAPI(apiPostHomeSliderSEO(formData));
            if (response.status === 200 || response.status === 201) {
                setAlert({
                    type: 'success',
                    title: 'Success',
                    message: 'Success update SEO',
                });
                setShowAlert(true);
                fetchHomeSEO();
            } else {
                const errMsg = response.error.message;
                setAlert({
                    type: 'error',
                    title: 'Error Home SEO',
                    message: errMsg,
                });
                setShowAlert(true);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlert({
                type: 'error',
                title: 'Error Home SEO',
                message: errMsg,
            });
            setShowAlert(true);
            setLoading(false);
        }
    };

    const handleSubmit = () => {
        let isError = false;
        let newErrorObj = Object.assign({}, error);
        for (let key in data) {
            if ((!data[key] || validateEmptySpace(data[key]))) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleDataHomeSEO();
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            setError(newErrorObj);
            setAlert({
                type: 'error',
                title: 'Error Home SEO',
                message: "There's an error on input field",
            });
            setShowAlert(true);
        }
    };

    return (
        <Fragment>
            <div className="form__wrapper">
                {
                    showAlert && (
                        <Alert 
                            style={{ marginBottom: '16px', textAlign: 'left' }}
                            type={alert.type}
                            message={alert.title}
                            description={alert.message} 
                            showIcon
                        />
                    )
                }
                <div className="form__field">
                    <div className="form__field-label">SEO - Meta Title</div>
                    <Input
                        name="seo_meta_title"
                        value={data.seo_meta_title}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.seo_meta_title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">SEO - Meta Description</div>
                    <Input
                        name="seo_meta_description"
                        value={data.seo_meta_description}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.seo_meta_description}
                    </div>
                </div>
                <div className="form__button">
                    <Row>
                        <Col span={3}>
                            <Button className="button" type="primary" onClick={() => handleSubmit()}>
                                Update
                            </Button>
                        </Col>
                        <Col offset={2} span={3}>
                            <Button className="button" type="danger" onClick={() => handleReset()}>
                                Reset Form
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default SEOSlider;