import { useState } from 'react';
import {
    Breadcrumb,
    Tabs,
} from 'antd';
import AboutSlider from './lib/about';
// import ContactSlider from './lib/contact';
import WorkSlider from './lib/slider';
import SEOSlider from './lib/seo';
import CMSLayout from '../../components/layout/cms';

const { TabPane } = Tabs;

const Home = (props) => {
    const [loading, setLoading] = useState(false);
    const [reFetch, setReFetch] = useState(true);

    const changeTabs = () => {
        setReFetch(true);
    };

    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>
                Homepage Slider
            </h1>
            <Tabs className="home__tabs" defaultActiveKey="1" onChange={() => changeTabs()}>
                <TabPane tab="Works Slider" key="1">
                    <WorkSlider
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
                <TabPane tab="About Slider" key="2">
                    <AboutSlider
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
                {/* <TabPane tab="Contact Slider" key="3">
                    <ContactSlider
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane> */}
                <TabPane tab="SEO" key="4">
                    <SEOSlider
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
            </Tabs>
        </CMSLayout>
    );
};

export default Home;