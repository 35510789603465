import { Fragment, useEffect, useState } from 'react';
import {
    Alert,
    Row,
    Col,
    Breadcrumb,
    Button,
    Input,
    Select,
    Tooltip,
    Modal,
    message,
} from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import {
    apiGetBlogsList,
    apiGetBlogsText,
    apiPostBlogsDetail,
    apiPostBlogsText,
} from "../../api/blogs";
import CMSLayout from '../../components/layout/cms';
import {
    validateEmptySpace,
} from '../../utils/validations';
import { statusOpt } from '../../utils/variablesObj';
import { RequestAPI } from '../../utils/requestApi';

const { Option } = Select;
const { confirm } = Modal;

const Blogs = (props) => {
    const [getData, setGetData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [tableLoad, setTableLoad] = useState(false);
    const [data, setData] = useState([]);
    const [totalRow, setTotalRow] = useState(0);
    const [quickChange, setQuickChange] = useState({
        id: '',
        value: '',
        changeData: '',
    });
    const [errorQuick, setErrorQuick] = useState('');
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
        columns: [],
        order: [
            {column: "date", dir: "desc"},
        ],
        start: 0,
        length: 10,
        search: {
            value: ''
        },
    });
    const [dataObj, setDataObj] = useState({
        blog_list_title: '',
        seo_meta_description: '',
        seo_meta_title: '',
    });
    const [error, setError] = useState({
        blog_list_title: '',
        seo_meta_description: '',
        seo_meta_title: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });

    const fetchBlogsText = async () => {
        setLoading(true);
        try {
            const response = await RequestAPI(apiGetBlogsText());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newDataObj = Object.assign({}, dataObj);
                for (let key in newDataObj) {
                    newDataObj[key] = resData[key];
                }
                setDataObj(newDataObj);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const fetchBlogsList = async (filterData) => {
        setTableLoad(true);
        try {
            const response = await RequestAPI(apiGetBlogsList(filterData));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                const resDataTotal = response.data.recordsTotal;
                let newFilterObj = Object.assign({}, filterData);
                newFilterObj['columns'] = [];
                columns.map((item) => {
                    let columnData =  {
                        data: "",
                        searchable: "true",
                        search: {
                            value: ""
                        }
                    };
                    if (item.searchable) {
                        columnData.data = item.selector;
                        newFilterObj['columns'].push(columnData);
                    }
                    return columnData;
                });
                setTotalRow(resDataTotal);
                setTableLoad(false);
                setData(resData);
                setFilter(newFilterObj);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setTableLoad(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setTableLoad(false);
        }
    };

    useEffect(() => {
        if (getData) {
            fetchBlogsList(filter);
            fetchBlogsText();
            setGetData(false);
        };
    // eslint-disable-next-line
    },[getData]);

    const handleDelete = (id) => {
        confirm({
            title: "Are you sure want to delete this works data?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let newDataObj = {
                    id: id,
                    changeData: 'status',
                    value: 'Trash',
                };
                handleQuickSubmit(newDataObj);
            },
            onCancel() { return }
        });
    };

    const handleClick = (id) => {
        if (id) {
            window.location.href = process.env.REACT_APP_BASE_URL + "/blogs/edit/" + id;
        } else {
            window.location.href = process.env.REACT_APP_BASE_URL + "/blogs/new";
        }
    };

    const handleQuickChange = (e, inputName) => {
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newQuickObj = Object.assign({}, quickChange);

        newQuickObj[name] = value;

        setQuickChange(newQuickObj);
        setErrorQuick('');
    };
    
    const handleQuickSubmit = async (form) => {
        setTableLoad(true);
        try {
            let formData = new FormData();
            const dataInput = form ? form : quickChange;
            formData.append('string_id', dataInput.id);
            formData.append(dataInput.changeData, dataInput.value);
            const response = await RequestAPI(apiPostBlogsDetail(formData));
            if (response.status === 200 || response.status === 201) {
                if (form) {
                    message.success("Success delete data");
                } else {
                    message.success("Success update data");
                }
                setQuickChange({
                    id: '',
                    value: '',
                    changeData: '',
                });
                fetchBlogsList(filter);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setTableLoad(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setTableLoad(false);
        }
    };

    const handleQuickData = (e) => {
        e.preventDefault();
        let isError = false;
        let errMsg = '';
        for (let key in quickChange) {
            if (!quickChange[key]) {
                errMsg = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleQuickSubmit();
        } else {
            setErrorQuick(errMsg);
        }
    };

    const resetForm = () => {
        setQuickChange({
            id: '',
            value: '',
            changeData: '',
        });
        setErrorQuick('');
    };

    const columns = [
        {
            name: 'Title',
            selector: 'title',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            searchable: true,
            cell: (record) => {
                return (
                    <div>
                        {moment(record.date).format("DD-MMM-YYYY")}
                    </div>
                )
            }
        },
        {
            name: 'Categories',
            selector: 'categories',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            searchable: true,
            cell: (record) => {
                if (record.string_id === quickChange.id && quickChange.changeData === "status") {
                    return (
                        <div style={{ position: 'relative' }}>
                            <form onSubmit={(e) => handleQuickData(e)}>
                                <Select
                                    name="value"
                                    value={quickChange.value}
                                    style={{ width: '150px' }}
                                    onChange={(e) => handleQuickChange(e, 'value')}
                                >
                                    {
                                        statusOpt.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.value}>{item.label}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                <div
                                    className="form__check"
                                    onClick={(e) => handleQuickData(e)}
                                >
                                    <CheckOutlined />
                                </div>
                                <div
                                    className="form__close"
                                    onClick={() => resetForm()}
                                >
                                    <CloseOutlined />
                                </div>
                                <div className="form__field--error">
                                    {errorQuick}
                                </div>
                            </form>
                        </div>
                    )
                } else {
                    return (
                        <Tooltip placement="right" title={'double click to change'}>
                            <div 
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onDoubleClick={() => {
                                    let newQuickObj = Object.assign({}, quickChange);
                                    newQuickObj.id = record.string_id;
                                    newQuickObj.value = record.status;
                                    newQuickObj.changeData = "status";
                                    setQuickChange(newQuickObj);
                                }}>
                                {record.status}
                            </div>
                        </Tooltip>
                    )
                }
            },
        },
        {
            name: 'Action',
            width: '200px',
            button: true,
            cell: (record) => {
                return (
                    <Fragment>
                        <Button type="primary" onClick={() => handleClick(record.string_id)}>Edit</Button>
                        <Button type="danger" style={{ marginLeft: '8px' }} onClick={() => handleDelete(record.string_id)}>Delete</Button>
                    </Fragment>
                )
            },
        },
    ];

    const handlePerRowsChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['length'] = e;

        fetchBlogsList(newFilterObj);
    };

    const handlePageChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['start'] = (e * newFilterObj.length) - newFilterObj.length;

        fetchBlogsList(newFilterObj);
    };

    const handleSort = (e, dir) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['order'] = [
            {
                column: e.selector,
                dir: dir,
            }
        ];

        fetchBlogsList(newFilterObj);
    };

    const handleFilter = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: search,
        };

        fetchBlogsList(newFilterObj);
    };

    const handleReset = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: '',
        };

        fetchBlogsList(newFilterObj);
        setSearch('');
    };

    const handleChange = (e, inputName) => {
        setShowAlert(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, dataObj);
        let newErrorObj = Object.assign({}, error);

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setDataObj(newDataObj);
        setError(newErrorObj);
    };

    const handleDataBlogsText = async () => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            let formData = new FormData();
            for (let key in dataObj) {
                formData.append(key, dataObj[key]);
            };
            const response = await RequestAPI(apiPostBlogsText(formData));
            if (response.status === 200 || response.status === 201) {
                setAlert({
                    type: 'success',
                    title: 'Success',
                    message: 'Success Update Blogs Title & SEO',
                });
                setShowAlert(true);
                fetchBlogsText();
            } else {
                const errMsg = response.error.message;
                setAlert({
                    type: 'error',
                    title: 'Error Blogs Title & SEO',
                    message: errMsg,
                });
                setShowAlert(true);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlert({
                type: 'error',
                title: 'Error Blogs Title & SEO',
                message: errMsg,
            });
            setShowAlert(true);
            setLoading(false);
        }
    };

    const handleSubmit = () => {
        let isError = false;
        let newErrorObj = Object.assign({}, error);
        for (let key in dataObj) {
            if (!dataObj[key] || validateEmptySpace(dataObj[key])) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleDataBlogsText();
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            setError(newErrorObj);
            setAlert({
                type: 'error',
                title: 'Error Blogs Title & SEO',
                message: "There's an error on input field",
            });
            setShowAlert(true);
        }
    };

    const handleResetForm = () => {
        let newErrorObj = Object.assign({}, error);
        for (let k in newErrorObj) {
            newErrorObj[k] = '';
        }
        
        fetchBlogsText();
        setError(newErrorObj);
        setShowAlert(false);
    };

    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>Blogs</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>
                Blogs List
            </h1>
            <div className="cms__container">
                {
                    showAlert && (
                        <Alert 
                            style={{ marginBottom: '16px', textAlign: 'left' }}
                            type={alert.type}
                            message={alert.title}
                            description={alert.message} 
                            showIcon
                        />
                    )
                }
                <div className="form__field">
                    <div className="form__field-label">Blog List Title</div>
                    <Input
                        name="blog_list_title"
                        value={dataObj.blog_list_title}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.blog_list_title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">SEO - Meta Title</div>
                    <Input
                        name="seo_meta_title"
                        value={dataObj.seo_meta_title}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.seo_meta_title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">SEO - Meta Description</div>
                    <Input
                        name="seo_meta_description"
                        value={dataObj.seo_meta_description}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.seo_meta_description}
                    </div>
                </div>
                <div className="form__field">
                    <Row>
                        <Col span={3}>
                            <Button className="button--full-width" type="primary" onClick={() => handleSubmit()}>
                                Update
                            </Button>
                        </Col>
                        <Col span={3} style={{ marginLeft: '8px' }}>
                            <Button className="button--full-width" type="danger" onClick={() => handleResetForm()}>
                                Reset Form
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="home__filter">
                    <Row align="bottom">
                        {/* <Col span={4}>
                            <div className="form__field" style={{ margin: '0', paddingRight: '8px' }}>
                                <div className="form__field-label">Filter by</div>
                                <Select>
                                    {
                                        optionData.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.value}>{item.label}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </Col> */}
                        <Col span={6}>
                            <div className="form__field" style={{ margin: '0', padding: '0 8px' }}>
                                <div className="form__field-label">Search by</div>
                                <Input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                            <Button className="button--full-width" type="primary" onClick={() => handleFilter()}>
                                Apply Filter
                            </Button>
                        </Col>
                        <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                            <Button className="button--full-width" type="danger" onClick={() => handleReset()}>
                                Reset Filter
                            </Button>
                        </Col>
                        <Col offset={9} span={3} style={{ padding: '0 8px' }}>
                            <Button className="button--full-width" type="primary" onClick={() => handleClick()}>
                                Add New +
                            </Button>
                        </Col>
                    </Row>
                </div>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={tableLoad}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRow}
                    onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                    onChangePage={(e) => handlePageChange(e)}
                    onSort={(e, dir) => handleSort(e, dir)}
                    sortServer
                />
            </div>
        </CMSLayout>
    );
};

export default Blogs;