import { v4 as uuidv4 } from 'uuid';
import Bowser from "bowser";
const getBaseUrl = process.env.REACT_APP_API_URL;
const browser = Bowser.getParser(window.navigator.userAgent);
const deviceType = `platform: ${browser.parsedResult.platform.type}, os: ${browser.parsedResult.os.name}, browser: ${browser.parsedResult.browser.name}`;

export const apiGetToken = () => ({
    body: {
        app_name: process.env.REACT_APP_TOKEN_APP_NAME,
        app_secret: process.env.REACT_APP_TOKEN_APP_SECRET,
        device_id: uuidv4(),
        device_type: deviceType,
    },
    method: 'POST',
    url: `${getBaseUrl}/token/get`,
});