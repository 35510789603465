import { useEffect, useState } from 'react';
import {
    Alert,
    Breadcrumb,
    Row,
    Col,
    Button,
    Input,
    message,
} from 'antd';
import {
    apiGetGeneralSetting,
    apiPostGeneralSetting,
} from '../../api/setting';
import CMSLayout from '../../components/layout/cms';
import {
    validateEmptySpace,
} from '../../utils/validations';
import { RequestAPI } from '../../utils/requestApi';

const GeneralSettings = (props) => {
    const [getData, setGetData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        ga_key: '',
        seo_meta_title: '',
        seo_meta_description: '',
    });
    const [error, setError] = useState({
        ga_key: '',
        seo_meta_title: '',
        seo_meta_description: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });

    const fetchGeneralDetail = async () => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            const response = await RequestAPI(apiGetGeneralSetting());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newDataObj = Object.assign({}, data);
                for (let key in newDataObj) {
                    newDataObj[key] = resData[key];
                };
                setData(newDataObj);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (getData) {
            fetchGeneralDetail();
            setGetData(false);
        }
    // eslint-disable-next-line
    }, [getData]);

    const handleDataGeneral = async () => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            };
            const response = await RequestAPI(apiPostGeneralSetting(formData));
            if (response.status === 200 || response.status === 201) {
                setAlert({
                    type: 'success',
                    title: 'Success',
                    message: 'Success update setting',
                });
                setShowAlert(true);
                setLoading(false);
                fetchGeneralDetail();
            } else {
                const errMsg = response.error.message;
                setAlert({
                    type: 'error',
                    title: 'Error General Setting',
                    message: errMsg,
                });
                setShowAlert(true);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlert({
                type: 'error',
                title: 'Error General Setting',
                message: errMsg,
            });
            setShowAlert(true);
            setLoading(false);
        }
    };

    const handleChange = (e, inputName) => {
        setShowAlert(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setData(newDataObj);
        setError(newErrorObj);
    };

    const handleSubmit = () => {
        let isError = false;
        let newErrorObj = Object.assign({}, error);
        for (let key in data) {
            if (!data[key] || validateEmptySpace(data[key])) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleDataGeneral();
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            setError(newErrorObj);
            setAlert({
                type: 'error',
                title: 'Error General Setting',
                message: "There's an error on input field",
            });
            setShowAlert(true);
        }
    };

    const handleReset = () => {
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);
        for (let k in newDataObj) {
            newErrorObj[k] = '';
        }
        
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        fetchGeneralDetail();
        setError(newErrorObj);
        setShowAlert(false);
    };

    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        Setting - General Settings
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>
                General Settings
            </h1>
            {
                showAlert && (
                    <Alert 
                        style={{ marginBottom: '16px', textAlign: 'left' }}
                        type={alert.type}
                        message={alert.title}
                        description={alert.message} 
                        showIcon
                    />
                )
            }
            <div className="cms__container">
                <div className="form__wrapper">
                    <div className="form__field">
                        <div className="form__field-label">General SEO - Meta Title</div>
                        <Input
                            name="seo_meta_title"
                            value={data.seo_meta_title}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.seo_meta_title}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">General SEO - Meta Description</div>
                        <Input
                            name="seo_meta_description"
                            value={data.seo_meta_description}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.seo_meta_description}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Goggle Analytics key</div>
                        <Input
                            name="ga_key"
                            value={data.ga_key}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.ga_key}
                        </div>
                    </div>
                    <div className="form__button">
                        <Row>
                            <Col span={3}>
                                <Button className="button" type="primary" onClick={() => handleSubmit()}>
                                    Update
                                </Button>
                            </Col>
                            <Col offset={2} span={3}>
                                <Button className="button" type="danger" onClick={() => handleReset()}>
                                    Reset Form
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </CMSLayout>
    );
};

export default GeneralSettings;