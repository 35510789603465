import { useEffect, useState } from 'react';
import {
    Alert,
    Breadcrumb,
    Row,
    Col,
    Button,
    Input,
    Checkbox,
    Modal,
    message,
} from 'antd';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
    apiGetRoleDetail,
    apiGetMenuList,
    apiPostRole,
} from '../../api/role';
import CMSLayout from '../../components/layout/cms';
import {
    validateEmptySpace,
} from '../../utils/validations';
import { RequestAPI } from '../../utils/requestApi';

const { confirm } = Modal;

const RoleDetail = (props) => {
    const [getData, setGetData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [paramsId, setParamsId] = useState('');
    const [data, setData] = useState({
        status: 'Active',
        name: '',
        menu_ids: [],
    });
    const [error, setError] = useState({
        name: '',
        menu_ids: '',
    });
    const [menuOption, setMenuOption] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });
    const {
        match,
    } = props;

    const fetchRoleDetail = async (id) => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            const body = { id };
            const response = await RequestAPI(apiGetRoleDetail(body));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newDataObj = Object.assign({}, data);
                newDataObj['menu_ids'] = [];
                for (let key in newDataObj) {
                    if (key !== 'menu_ids') {
                        newDataObj[key] = resData.role[key];
                    }
                }
                resData.list_menu.map((item) => {
                    if (item.app_admin_role_menu) {
                        newDataObj['menu_ids'].push(item.id);
                        return item;
                    }
                    return item;
                });
                setData(newDataObj);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const fetchMenuList = async () => {
        setLoading(true);
        try {
            const body = { id: 0 };
            const response = await RequestAPI(apiGetMenuList(body));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let menuArr = [];
                resData.list_menu.map((item) => {
                    if (item.status === "Active" && item.name !== "Logout") {
                        let dataObj = {
                            value: item.id,
                            label: item.name,
                        };
                        menuArr.push(dataObj);
                        return dataObj;
                    };
                    return item;
                });
                setMenuOption(menuArr);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (getData) {
            if (match.params.id) {
                setParamsId(match.params.id);
                fetchRoleDetail(match.params.id);
            }
            fetchMenuList();
            setGetData(false);
        }
    // eslint-disable-next-line
    }, [getData, match]);

    const handleDataRole = async (dataObj) => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            let formData = new FormData();
            const dataBody = dataObj ? dataObj : data;
            for (let key in dataBody) {
                if (key === "menu_ids") {
                    let dataOrder = dataBody[key].sort((a, b) => {return a-b});
                    formData.append(key, dataOrder);
                } else {
                    formData.append(key, dataBody[key]);
                }
            };
            if (paramsId) {
                formData.append('id', paramsId);
            }
            const response = await RequestAPI(apiPostRole(formData));
            if (response.status === 200 || response.status === 201) {
                if (dataBody['status'] === "Trash") {
                    setAlert({
                        type: 'success',
                        title: 'Success',
                        message: 'Success delete role',
                    });
                } else {
                    setAlert({
                        type: 'success',
                        title: 'Success',
                        message: paramsId ? 'Success update role' : 'Success create role',
                    });
                }
                setShowAlert(true);
                setLoading(false);
                if (paramsId && dataBody['status'] !== "Trash") {
                    fetchRoleDetail(paramsId);
                } else {
                    setTimeout(() => {
                        window.location.href = process.env.REACT_APP_BASE_URL + "/account/role";
                    }, 1000);
                }
            } else {
                const errMsg = response.error.message;
                setAlert({
                    type: 'error',
                    title: 'Error Role',
                    message: errMsg,
                });
                setShowAlert(true);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlert({
                type: 'error',
                title: 'Error Role',
                message: errMsg,
            });
            setShowAlert(true);
            setLoading(false);
        }
    };

    const handleChange = (e, inputName) => {
        setShowAlert(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);

        if (name === "menu_ids") {
            let sameValue = false;
            newDataObj[name].map((item) => {
                if (item === value) {
                    sameValue = true;
                    return sameValue;
                }
                return item;
            });

            if (sameValue) {
                const numberIndex = newDataObj[name].indexOf(value);
                newDataObj[name].splice(numberIndex,1);
            } else {
                newDataObj[name].push(value);
            }

            if (newDataObj[name].length > 0) {
                newErrorObj[name] = '';
            }
        } else {
            newDataObj[name] = value;
            newErrorObj[name] = '';
        }

        setData(newDataObj);
        setError(newErrorObj);
    };

    const handleSubmit = () => {
        let isError = false;
        let newErrorObj = Object.assign({}, error);
        for (let key in data) {
            if (key === "menu_ids" && data[key].length === 0) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }

            if ((!data[key] || validateEmptySpace(data[key])) && key !== "menu_ids") {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleDataRole();
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            setError(newErrorObj);
            setAlert({
                type: 'error',
                title: 'Error Role',
                message: "There's an error on input field",
            });
            setShowAlert(true);
        }
    };

    const handleReset = () => {
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);
        for (let k in newDataObj) {
            newErrorObj[k] = '';
        }
        
        if (paramsId) {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            fetchRoleDetail(paramsId);
        } else {
            window.location.href = process.env.REACT_APP_BASE_URL + "/account/role";
        }
        setError(newErrorObj);
        setShowAlert(false);
    };

    const handleDelete = () => {
        confirm({
            title: "Are you sure want to delete this role?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let newDataObj = Object.assign({}, data);
                newDataObj['status'] = "Trash";

                handleDataRole(newDataObj);
            },
            onCancel() { return }
        });
    };

    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href={process.env.REACT_APP_BASE_URL + "/account/role"}>Role</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {
                            paramsId ? (
                                <span>Role {data.name}</span>
                            ) : (
                                <span>Add New Role</span>
                            )
                        }
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row>
                <Col span={18}>
                    <h1>
                        {
                            paramsId ? (
                                <div>Role {data.name}</div>
                            ) : (
                                <div>Add New Role</div>
                            )
                        }
                    </h1>
                </Col>
                {
                    paramsId && (
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <Button className="button" type="danger" onClick={() => handleDelete()}>
                                <DeleteOutlined /> Delete
                            </Button>
                        </Col>
                    )
                }
            </Row>
            {
                showAlert && (
                    <Alert 
                        style={{ marginBottom: '16px', textAlign: 'left' }}
                        type={alert.type}
                        message={alert.title}
                        description={alert.message} 
                        showIcon
                    />
                )
            }
            <div className="cms__container">
                <div className="form__wrapper">
                    <div className="form__field">
                        <div className="form__field-label">Role Name</div>
                        <Input
                            name="name"
                            value={data.name}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.name}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Permission Menu List</div>
                        <div className="form__twice-width">
                            <Row>
                                {
                                    menuOption.map((item, index) => {
                                        return (
                                            <Col style={{ marginBottom: '16px' }} span={6} key={index}>
                                                <Checkbox checked={data.menu_ids.includes(item.value)} value={item.value} name="menu_ids" onChange={(e) => handleChange(e)}>{item.label}</Checkbox>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            <div className="form__field--error">
                                {error.menu_ids}
                            </div>
                        </div>
                    </div>
                    <div className="form__button">
                        <Row>
                            <Col span={3}>
                                <Button className="button" type="primary" onClick={() => handleSubmit()}>
                                    {
                                        paramsId ? (
                                            "Update"
                                        ) : (
                                            "Create"
                                        )
                                    }
                                </Button>
                            </Col>
                            <Col offset={2} span={3}>
                                <Button className="button" type="danger" onClick={() => handleReset()}>
                                    {
                                        paramsId ? (
                                            "Reset Form"
                                        ) : (
                                            "Cancel"
                                        )
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </CMSLayout>
    );
};

export default RoleDetail;