import { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button,
    Breadcrumb,
    Tabs,
    Modal,
    message,
} from 'antd';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import WorksContent from './lib/content';
import WorkImage from './lib/images';
import {
    apiGetWorksDetail,
    apiPostWorksDetail,
} from "../../api/works";
import CMSLayout from '../../components/layout/cms';
import { RequestAPI } from '../../utils/requestApi';

const { TabPane } = Tabs;
const { confirm } = Modal;

const WorkDetail = (props) => {
    const [getData, setGetData] = useState(true);
    const [dataDetail, setDataDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [reFetch, setReFetch] = useState(false);
    const [paramsId, setParamsId] = useState('');
    const {
        match,
    } = props;

    const fetchWorksDetail = async (id) => {
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('string_id', id);
            const response = await RequestAPI(apiGetWorksDetail(formData));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                setDataDetail(resData);
                setLoading(false);
                setReFetch(true);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (getData) {
            if (match.params.id) {
                setParamsId(match.params.id);
                fetchWorksDetail(match.params.id);
            } else {
                setReFetch(true);
            }
            setGetData(false);
        }
    }, [getData, match]);

    const changeTabs = () => {
        setReFetch(true);
    };

    const handleDeleteData = async (form) => {
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('string_id', form.id);
            formData.append(form.changeData, form.value);
            const response = await RequestAPI(apiPostWorksDetail(formData));
            if (response.status === 200 || response.status === 201) {
                message.success("Success delete data");
                setTimeout(() => {
                    window.location.href = process.env.REACT_APP_BASE_URL + "/works";
                }, 1000);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleDelete = () => {
        confirm({
            title: "Are you sure want to delete this works data?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let newDataObj = {
                    id: paramsId,
                    changeData: 'status',
                    value: 'Trash',
                };
                handleDeleteData(newDataObj);
            },
            onCancel() { return }
        });
    };

    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href={process.env.REACT_APP_BASE_URL + "/works"}>Works</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {
                            paramsId ? (
                                <span>{dataDetail.work_list_title}</span>
                            ) : (
                                <span>Add New Works</span>
                            )
                        }
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row>
                <Col span={18}>
                    <h1>
                        {
                            paramsId ? (
                                <div>{dataDetail.work_list_title}</div>
                            ) : (
                                <div>Add New Works</div>
                            )
                        }
                    </h1>
                </Col>
                {
                    paramsId && (
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <Button className="button" type="danger" onClick={() => handleDelete()}>
                                <DeleteOutlined /> Delete
                            </Button>
                        </Col>
                    )
                }
            </Row>
            <Tabs className="home__tabs" defaultActiveKey="1" onChange={() => changeTabs()}>
                <TabPane tab="Works Content" key="1">
                    <WorksContent
                        loading={loading}
                        paramsId={paramsId}
                        reFetch={reFetch}
                        dataDetail={dataDetail}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                        fetchWorksDetail={fetchWorksDetail}
                    />
                </TabPane>
                <TabPane tab="Works Images" disabled={paramsId ? false : true} key="2">
                    <WorkImage
                        loading={loading}
                        paramsId={paramsId}
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
            </Tabs>
        </CMSLayout>
    );
};

export default WorkDetail;