import { Fragment, useEffect, useState } from 'react';
import {
    Alert,
    Row,
    Col,
    Button,
    Input,
    message,
} from 'antd';
import {
    apiGetHomeSliderAbout,
    apiPostHomeSliderAbout,
} from "../../../api/home";
import {
    validateEmptySpace,
} from '../../../utils/validations';
import { RequestAPI } from '../../../utils/requestApi';

const { TextArea } = Input;

const AboutSlider = (props) => {
    const [data, setData] = useState({
        title_row_1: '',
        title_row_2: '',
        title_row_3: '',
        description: '',
        button_text: '',
        sequence: '',
        slider_images: '',
    });
    const [sliderImg, setSliderImg] = useState('');
    const [previewImg, setPreviewImg] = useState('');
    const [error, setError] = useState({
        title_row_1: '',
        title_row_2: '',
        title_row_3: '',
        description: '',
        button_text: '',
        sequence: '',
        slider_images: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });
    const {
        reFetch,
        setReFetch,
        setLoading,
    } = props;

    const fetchHomeAbout = async () => {
        setLoading(true);
        try {
            const response = await RequestAPI(apiGetHomeSliderAbout());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newDataObj = Object.assign({}, data);
                for (let key in newDataObj) {
                    if (key === "slider_images") {
                        newDataObj[key] = "";
                        setSliderImg(resData['images']);
                        setPreviewImg(resData['images']);
                    } else {
                        newDataObj[key] = resData[key];
                    }
                };
                setData(newDataObj);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleReset = () => {
        let newErrorObj = Object.assign({}, error);
        for (let k in newErrorObj) {
            newErrorObj[k] = '';
        }
        
        fetchHomeAbout();
        setError(newErrorObj);
        setShowAlert(false);
    };

    useEffect(() => {
        if (reFetch) {
            setShowAlert(false);
            setReFetch(false);
            handleReset();
        }
    // eslint-disable-next-line
    },[reFetch]);

    const handleChange = (e, inputName) => {
        setShowAlert(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);

        if (name === "slider_images") {
            const file = e.target.files[0];
            
            let reader = new FileReader();
            // eslint-disable-next-line
            let url = reader.readAsDataURL(file);

            reader.onloadend = (e) => {
                setPreviewImg([reader.result]);
            }
            setSliderImg(file);
        }

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setData(newDataObj);
        setError(newErrorObj);
    };

    const handleDataHomeAbout = async () => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            let formData = new FormData();
            for (let key in data) {
                if (key === "slider_images" && data[key]) {
                    formData.append(key, sliderImg);
                } else {
                    formData.append(key, data[key]);
                }
            };
            const response = await RequestAPI(apiPostHomeSliderAbout(formData));
            if (response.status === 200 || response.status === 201) {
                setAlert({
                    type: 'success',
                    title: 'Success',
                    message: 'Success update slide',
                });
                setShowAlert(true);
                fetchHomeAbout();
            } else {
                const errMsg = response.error.message;
                setAlert({
                    type: 'error',
                    title: 'Error About Slider',
                    message: errMsg,
                });
                setShowAlert(true);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlert({
                type: 'error',
                title: 'Error About Slider',
                message: errMsg,
            });
            setShowAlert(true);
            setLoading(false);
        }
    };

    const handleSubmit = () => {
        let isError = false;
        let newErrorObj = Object.assign({}, error);
        for (let key in data) {
            if (key === "slider_images" && !sliderImg) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }

            if ((!data[key] || validateEmptySpace(data[key])) && key !== "slider_images") {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleDataHomeAbout();
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            setError(newErrorObj);
            setAlert({
                type: 'error',
                title: 'Error About Slider',
                message: "There's an error on input field",
            });
            setShowAlert(true);
        }
    };

    return (
        <Fragment>
            <div className="form__wrapper">
                {
                    showAlert && (
                        <Alert 
                            style={{ marginBottom: '16px', textAlign: 'left' }}
                            type={alert.type}
                            message={alert.title}
                            description={alert.message} 
                            showIcon
                        />
                    )
                }
                <div className="form__field">
                    <div className="form__field-label">Title Row 1</div>
                    <Input
                        name="title_row_1"
                        value={data.title_row_1}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.title_row_1}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Title Row 2</div>
                    <Input
                        name="title_row_2"
                        value={data.title_row_2}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.title_row_2}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Title Row 3</div>
                    <Input
                        name="title_row_3"
                        value={data.title_row_3}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.title_row_3}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Descirption Text</div>
                    <TextArea
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        name="description"
                        value={data.description}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.description}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Button Text</div>
                    <Input
                        name="button_text"
                        value={data.button_text}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.button_text}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Image File</div>
                    <div className="form__field-upload">
                        <input
                            type="file"
                            name="slider_images"
                            value={data.slider_images}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="form__field--info">
                        Notes
                        <div>
                            - Ratio 3:4
                        </div>
                        <div>
                            - Max dimension 532 x 709
                        </div>
                    </div>
                    <div className="form__field--error">
                        {error.slider_images}
                    </div>
                    {
                        previewImg && (
                            <div className="form__field--img-preview">
                                <div>Image Preview:</div>
                                <img src={previewImg} alt={"img-preview"}/>
                            </div>
                        )
                    }
                </div>
                <div className="form__button">
                    <Row>
                        <Col span={3}>
                            <Button className="button" type="primary" onClick={() => handleSubmit()}>
                                Update
                            </Button>
                        </Col>
                        <Col offset={2} span={3}>
                            <Button className="button" type="danger" onClick={() => handleReset()}>
                                Reset Form
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default AboutSlider;