export const hasSubmenu = [
    'master',
    'account',
    'setting'
];

export const statusOpt = [
    {
        value: 'Active',
        label: 'Active',
    },
    {
        value: 'Inactive',
        label: 'Inactive',
    }
];