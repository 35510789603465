const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetGeneralSetting = () => ({
    method: 'POST',
    url: `${getBaseUrl}/general-setting/get`,
    isAuthorize: true,
});

export const apiPostGeneralSetting = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/general-setting/insert`,
    isAuthorize: true,
});

export const apiGetSocialMedia = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/social-media/get`,
    isAuthorize: true,
});

export const apiGetSocialMediaDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/social-media/get-detail`,
    isAuthorize: true,
});

export const apiGetWA = () => ({
    method: 'POST',
    url: `${getBaseUrl}/social-media/get-wa`,
    isAuthorize: true,
});

export const apiPostWA = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/social-media/insert-wa`,
    isAuthorize: true,
});

export const apiPostSocialMedia = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/social-media/insert`,
    isAuthorize: true,
});