const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetWorksCategories = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/master-data/get-work-categories`,
    isAuthorize: true,
});

export const apiPostWorksCategories = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/master-data/insert-work-categories`,
    isAuthorize: true,
});

export const apiGetBlogsCategories = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/master-data/get-blog-categories`,
    isAuthorize: true,
});

export const apiPostBlogsCategories = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/master-data/insert-blog-categories`,
    isAuthorize: true,
});