const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiLogin = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/login`,
    isAuthorize: true,
});

export const apiGetAccessMenu = () => ({
    method: 'POST',
    url: `${getBaseUrl}/admin/menu`,
    isAuthorize: true,
});