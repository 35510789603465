import { useState } from 'react';
import {
    Breadcrumb,
    Tabs,
} from 'antd';
import ContactMessage from './lib/message';
import ContactContent from './lib/content';
import CMSLayout from '../../components/layout/cms';

const { TabPane } = Tabs;

const Contact = (props) => {
    const [loading, setLoading] = useState(false);
    const [reFetch, setReFetch] = useState(true);

    const changeTabs = () => {
        setReFetch(true);
    };
    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href={process.env.REACT_APP_BASE_URL + "/contact"}>Contact</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>
                Contact
            </h1>
            <Tabs className="home__tabs" defaultActiveKey="1" onChange={() => changeTabs()}>
                <TabPane tab="Contact Content" key="1">
                    <ContactContent
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
                <TabPane tab="Contact Message" key="2">
                    <ContactMessage
                        reFetch={reFetch}
                        setReFetch={setReFetch}
                        setLoading={setLoading}
                    />
                </TabPane>
            </Tabs>
        </CMSLayout>
    );
};

export default Contact;