import { useEffect, useState } from 'react';
import {
    Alert,
    Breadcrumb,
    Row,
    Col,
    Button,
    Input,
    Select,
    Modal,
    message,
} from 'antd';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { SketchPicker } from 'react-color';
import { apiGetWorksList } from "../../api/works";
import {
    apiPostHomeSliderWorks,
    apiGetHomeSliderWorksDetail,
} from "../../api/home";
import CMSLayout from '../../components/layout/cms';
import {
    validateEmptySpace,
    validateNumber,
} from '../../utils/validations';
import { statusOpt } from '../../utils/variablesObj';
import { RequestAPI } from '../../utils/requestApi';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

const SliderDetail = (props) => {
    const [getData, setGetData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [paramsId, setParamsId] = useState('');
    const [data, setData] = useState({
        status: '',
        works_id: '',
        small_title: '',
        big_title: '',
        button_text: '',
        background_color: '',
        slider_images: '',
        sequence: '',
    });
    const [sliderImg, setSliderImg] = useState('');
    const [previewImg, setPreviewImg] = useState('');
    const [error, setError] = useState({
        status: '',
        works_id: '',
        small_title: '',
        big_title: '',
        button_text: '',
        background_color: '',
        slider_images: '',
        sequence: '',
    });
    const [loadingOption, setLoadingOption] = useState(false);
    const [optionData, setOptionData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });
    const [filter, setFilter] = useState({
        columns: [{
            data: "title",
            searchable: "true",
            search: {
                value: ""
            }
        }],
        order: [],
        start: 0,
        length: 9999,
        search: {
            value: ''
        },
    });
    const {
        match,
    } = props;

    const fetchWorksList = async (filterData) => {
        setLoadingOption(true);
        try {
            const response = await RequestAPI(apiGetWorksList(filterData));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newOptionObj = [];
                resData.map((item) => {
                    if (item.status === "Active") {
                        let optionObj = {
                            value: parseInt(item.id),
                            label: item.title,
                        };

                        newOptionObj.push(optionObj);
                        return optionObj;
                    } else {
                        return item;
                    }
                });
                setFilter(filterData);
                setOptionData(newOptionObj);
                setLoadingOption(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoadingOption(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoadingOption(false);
        }
    };

    const fetchWorksDetail = async (id) => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            const body = { id };
            const response = await RequestAPI(apiGetHomeSliderWorksDetail(body));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newDataObj = Object.assign({}, data);
                for (let key in newDataObj) {
                    if (key === "slider_images") {
                        newDataObj[key] = "";
                        setSliderImg(resData['images']);
                        setPreviewImg(resData['images']);
                    } else {
                        newDataObj[key] = resData[key];
                    }
                };
                setData(newDataObj);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (getData) {
            if (match.params.id) {
                setParamsId(match.params.id);
                fetchWorksDetail(match.params.id);
            }
            fetchWorksList(filter);
            setGetData(false);
        }
    // eslint-disable-next-line
    }, [getData, match]);

    const handleChange = (e, inputName) => {
        setShowAlert(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);

        if (name === "slider_images") {
            const file = e.target.files[0];
            
            let reader = new FileReader();
            // eslint-disable-next-line
            let url = reader.readAsDataURL(file);

            reader.onloadend = (e) => {
                setPreviewImg([reader.result]);
            }
            setSliderImg(file);
        }

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setData(newDataObj);
        setError(newErrorObj);
    };

    const handleDataSlider = async (dataObj) => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            let formData = new FormData();
            const dataBody = dataObj ? dataObj : data;
            for (let key in dataBody) {
                if (key === "slider_images" && dataBody[key]) {
                    formData.append(key, sliderImg);
                } else {
                    formData.append(key, dataBody[key]);
                }
            };
            if (paramsId) {
                formData.append('id', paramsId);
            }
            const response = await RequestAPI(apiPostHomeSliderWorks(formData));
            if (response.status === 200 || response.status === 201) {
                if (dataBody['status'] === "Trash") {
                    setAlert({
                        type: 'success',
                        title: 'Success',
                        message: 'Success delete slide',
                    });
                } else {
                    setAlert({
                        type: 'success',
                        title: 'Success',
                        message: paramsId ? 'Success update slide' : 'Success create slide',
                    });
                }
                setShowAlert(true);
                setLoading(false);
                if (paramsId && dataBody['status'] !== "Trash") {
                    fetchWorksDetail(paramsId);
                } else {
                    setTimeout(() => {
                        window.location.href = process.env.REACT_APP_BASE_URL + "/home";
                    }, 1000);
                }
            } else {
                const errMsg = response.error.message;
                setAlert({
                    type: 'error',
                    title: 'Error Works Slider',
                    message: errMsg,
                });
                setShowAlert(true);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlert({
                type: 'error',
                title: 'Error Works Slider',
                message: errMsg,
            });
            setShowAlert(true);
            setLoading(false);
        }
    };

    const handleSubmit = () => {
        let isError = false;
        let newErrorObj = Object.assign({}, error);
        for (let key in data) {
            if (key === "slider_images" && !sliderImg) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            } else if (key === "sequence" && !validateNumber(data[key])) {
                newErrorObj[key] = "Numeric input only";
                isError = true;
            }

            if ((!data[key] || validateEmptySpace(data[key])) && key !== "slider_images") {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleDataSlider();
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            setError(newErrorObj);
            setAlert({
                type: 'error',
                title: 'Error Works Slider',
                message: "There's an error on input field",
            });
            setShowAlert(true);
        }
    };

    const handleReset = () => {
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);
        for (let k in newDataObj) {
            newErrorObj[k] = '';
        }
        
        if (paramsId) {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            fetchWorksDetail(paramsId);
        } else {
            window.location.href = process.env.REACT_APP_BASE_URL + "/home";
        }
        setError(newErrorObj);
        setShowAlert(false);
    };

    const handleDelete = () => {
        confirm({
            title: "Are you sure want to delete this slide?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let newDataObj = Object.assign({}, data);
                newDataObj['status'] = "Trash";

                handleDataSlider(newDataObj);
            },
            onCancel() { return }
        });
    };

    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href={process.env.REACT_APP_BASE_URL + "/home"}>Home</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {
                            paramsId ? (
                                <span>Slide {data.small_title}</span>
                            ) : (
                                <span>Add New Slide</span>
                            )
                        }
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Row>
                <Col span={18}>
                    <h1>
                        {
                            paramsId ? (
                                <div>Works Home Slider {data.small_title}</div>
                            ) : (
                                <div>Add New Slider</div>
                            )
                        }
                    </h1>
                </Col>
                {
                    paramsId && (
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <Button className="button" type="danger" onClick={() => handleDelete()}>
                                <DeleteOutlined /> Delete
                            </Button>
                        </Col>
                    )
                }
            </Row>
            {
                showAlert && (
                    <Alert 
                        style={{ marginBottom: '16px', textAlign: 'left' }}
                        type={alert.type}
                        message={alert.title}
                        description={alert.message} 
                        showIcon
                    />
                )
            }
            <div className="cms__container">
                <div className="form__wrapper">
                    <div className="form__field">
                        <div className="form__field-label">Status</div>
                        <Select 
                            value={data.status}
                            name="status"
                            onChange={(e) => handleChange(e, 'status')}
                        >
                            {
                                statusOpt.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.value}>{item.label}</Option>
                                    )
                                })
                            }
                        </Select>
                        <div className="form__field--error">
                            {error.status}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Slider Sequence</div>
                        <Input
                            name="sequence"
                            value={data.sequence}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.sequence}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Related Works List</div>
                        <Select 
                            showSearch
                            loading={loadingOption}
                            value={data.works_id}
                            name="works_id"
                            onChange={(e) => handleChange(e, 'works_id')}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                optionData.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.value}>{item.label}</Option>
                                    )
                                })
                            }
                        </Select>
                        <div className="form__field--error">
                            {error.works_id}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Small Title</div>
                        <Input 
                            name="small_title"
                            value={data.small_title}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.small_title}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Big Title</div>
                        <TextArea 
                            name="big_title"
                            value={data.big_title}
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.big_title}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Button Text</div>
                        <Input
                            name="button_text"
                            value={data.button_text}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="form__field--error">
                            {error.button_text}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Background Color</div>
                        <div style={{ marginBottom: '8px' }}>
                            <SketchPicker
                                name="background_color"
                                color={data.background_color || ''}
                                onChange={(e) => handleChange(e.hex, 'background_color')}
                            />
                        </div>
                        <Input disabled value={data.background_color} />
                        <div className="form__field--error">
                            {error.background_color}
                        </div>
                    </div>
                    <div className="form__field">
                        <div className="form__field-label">Image File</div>
                        <div className="form__field-upload">
                            <input
                                type="file"
                                name="slider_images"
                                value={data.slider_images}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className="form__field--info">
                            Notes
                            <div>
                                - Ratio 3:4
                            </div>
                            <div>
                                - Max dimension 532 x 709
                            </div>
                        </div>
                        <div className="form__field--error">
                            {error.slider_images}
                        </div>
                        {
                            previewImg && (
                                <div className="form__field--img-preview">
                                    <div>Image Preview:</div>
                                    <img src={previewImg} alt={"img-preview"}/>
                                </div>
                            )
                        }
                    </div>
                    <div className="form__button">
                        <Row>
                            <Col span={3}>
                                <Button className="button" type="primary" onClick={() => handleSubmit()}>
                                    {
                                        paramsId ? (
                                            "Update"
                                        ) : (
                                            "Create"
                                        )
                                    }
                                </Button>
                            </Col>
                            <Col offset={2} span={3}>
                                <Button className="button" type="danger" onClick={() => handleReset()}>
                                    {
                                        paramsId ? (
                                            "Reset Form"
                                        ) : (
                                            "Cancel"
                                        )
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </CMSLayout>
    );
};

export default SliderDetail;