const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetRoleList = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/account/get-role`,
    isAuthorize: true,
});

export const apiGetRoleDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/account/get-role-detail`,
    isAuthorize: true,
});

export const apiGetMenuList = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/account/all-menu`,
    isAuthorize: true,
});

export const apiPostRole = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/account/insert-role`,
    isAuthorize: true,
});