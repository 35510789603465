import React from "react";
import {
  message,
} from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { apiGetToken } from '../api/getToken';
import Login from '../pages/login';
import About from '../pages/about';
import Home from '../pages/home';
import SliderDetail from '../pages/slider-detail';
import Blogs from '../pages/blogs';
import BlogsDetail from '../pages/blogs-detail';
import Works from '../pages/works';
import WorksDetail from '../pages/works-detail';
import Contact from '../pages/contact';
import Category from '../pages/category';
import User from '../pages/user';
import Role from '../pages/role';
import RoleDetail from '../pages/role-detail';
import SocialMedia from '../pages/social-media';
import GeneralSettings from '../pages/general';
import NotFound from '../pages/not-found';
import history from '../utils/history';
import { RequestAPI } from '../utils/requestApi';

const LoginRoute = ({ component: Component, ...rest }: any) => {
  const getToken = localStorage.getItem('token');
  const loggedIn = localStorage.getItem('isLoggedIn');
  const getAccess = localStorage.getItem('access');
  let redirectURL = '';
  if (getAccess) {
      const accessJSON = JSON.parse(getAccess);
      redirectURL = accessJSON.[0].link;
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (getToken) {
          if (loggedIn === "false") {
            return (
              <Component {...props}/>
            )
          } else {
            window.location.href = process.env.REACT_APP_BASE_URL + redirectURL;
          }
        }
      }}
    />
  )
};


const DashboardRoute = ({ component: Component, ...rest }: any) => {
  const getToken = localStorage.getItem('token');
  const loggedIn = localStorage.getItem('isLoggedIn');
  const getAccess = localStorage.getItem('access');
  const accessJSON = JSON.parse(getAccess);
  let accessArr = [];
  if (accessJSON && accessJSON.length > 0) {
    accessJSON.map((item) => {
      accessArr.push(item.name);
      return accessArr;
    });
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (getToken) {
          if (loggedIn === "true") {
            if (accessArr.includes(rest.access)) {
              return (
                <Component {...props}/>
              )
            } else {
              window.location.href = process.env.REACT_APP_BASE_URL + "/404";
            }
          } else {
            window.location.href = process.env.REACT_APP_BASE_URL + "/";
          }
        }
      }}
    />
  )
};

const fetchToken = async () => {
  try {
    const response = await RequestAPI(apiGetToken());
    if (response.status === 200 || response.status === 201) {
      const resData = response.data.data;
      localStorage.setItem('token', resData.token_code);
      localStorage.setItem('expired_date', resData.expired_at);
      localStorage.setItem('isLoggedIn', false);
      window.location.reload();
    } else {
      const errMsg = response.error.message;
      message.error(errMsg);
    }
  } catch (error) {
    const errMsg = error.response ? error.response.data.error.message : error.message;
    message.error(errMsg);
  }
};

const AppRouter = () => {
  const getToken = localStorage.getItem('token');

  if (!getToken) {
    fetchToken();
  }

  return (
    <Router history={history}>
      <Switch>
        <LoginRoute exact={true} path="/" component={Login}></LoginRoute>
        <LoginRoute exact={true} path="/login" component={Login}></LoginRoute>
        <DashboardRoute exact={true} access={"Home"} path="/home" component={Home}></DashboardRoute>
        <DashboardRoute exact={true} access={"Home"} path="/home/slider" component={SliderDetail}></DashboardRoute>
        <DashboardRoute exact={true} access={"Home"} path="/home/slider/:id" component={SliderDetail}></DashboardRoute>
        <DashboardRoute exact={true} access={"About"} path="/about" component={About}></DashboardRoute>
        <DashboardRoute exact={true} access={"Blogs"} path="/blogs" component={Blogs}></DashboardRoute>
        <DashboardRoute exact={true} access={"Blogs"} path="/blogs/new" component={BlogsDetail}></DashboardRoute>
        <DashboardRoute exact={true} access={"Blogs"} path="/blogs/edit/:id" component={BlogsDetail}></DashboardRoute>
        <DashboardRoute exact={true} access={"Works"} path="/works" component={Works}></DashboardRoute>
        <DashboardRoute exact={true} access={"Works"} path="/works/new" component={WorksDetail}></DashboardRoute>
        <DashboardRoute exact={true} access={"Works"} path="/works/edit/:id" component={WorksDetail}></DashboardRoute>
        <DashboardRoute exact={true} access={"Contact"} path="/contact" component={Contact}></DashboardRoute>
        <DashboardRoute exact={true} access={"Master Data"} path="/master/category" component={Category}></DashboardRoute>
        <DashboardRoute exact={true} access={"Account"} path="/account/user" component={User}></DashboardRoute>
        <DashboardRoute exact={true} access={"Account"} path="/account/role" component={Role}></DashboardRoute>
        <DashboardRoute exact={true} access={"Account"} path="/account/role/new" component={RoleDetail}></DashboardRoute>
        <DashboardRoute exact={true} access={"Account"} path="/account/role/edit/:id" component={RoleDetail}></DashboardRoute>
        <DashboardRoute exact={true} access={"Setting"} path="/setting/social-media" component={SocialMedia}></DashboardRoute>
        <DashboardRoute exact={true} access={"Setting"} path="/setting/general" component={GeneralSettings}></DashboardRoute>
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};
export default AppRouter;