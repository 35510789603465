import { Fragment, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button,
    Input,
    Select,
    Tooltip,
    Modal,
    message,
} from 'antd';
import {
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import DataTable from 'react-data-table-component';
import {
    apiGetHomeSliderWorks,
    apiPostHomeSliderWorks,
} from "../../../api/home";
import {
    validateEmptySpace,
    validateNumber,
} from '../../../utils/validations';
import { statusOpt } from '../../../utils/variablesObj';
import { RequestAPI } from '../../../utils/requestApi';

const { Option } = Select;
const { confirm } = Modal;

const WorkSlider = (props) => {
    const [tableLoad, setTableLoad] = useState(false);
    const [data, setData] = useState([]);
    const [totalRow, setTotalRow] = useState(0);
    const [quickChange, setQuickChange] = useState({
        id: '',
        value: '',
        changeData: '',
    });
    const [errorQuick, setErrorQuick] = useState();
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
        columns: [],
        order: [],
        start: 0,
        length: 10,
        search: {
            value: ''
        },
    });
    const {
        reFetch,
        setReFetch,
        setLoading,
    } = props;

    const fetchHomeWorks = async (filterData) => {
        setTableLoad(true);
        try {
            const response = await RequestAPI(apiGetHomeSliderWorks(filterData));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                const resDataTotal = response.data.recordsTotal;
                let newFilterObj = Object.assign({}, filterData);
                newFilterObj['columns'] = [];
                columns.map((item) => {
                    let columnData =  {
                        data: "",
                        searchable: "true",
                        search: {
                            value: ""
                        }
                    };
                    if (item.searchable) {
                        columnData.data = item.selector;
                        newFilterObj['columns'].push(columnData);
                    }
                    return columnData;
                });
                setTotalRow(resDataTotal);
                setTableLoad(false);
                setData(resData);
                setFilter(newFilterObj);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setTableLoad(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setTableLoad(false);
        }
    };

    useEffect(() => {
        if (reFetch) {
            fetchHomeWorks(filter);
            setReFetch(false);
        };
    // eslint-disable-next-line
    },[reFetch]);

    const handleDeleteSlide = async (dataObj) => {
        setLoading(true);
        try {
            let formData = new FormData();
            for (let key in dataObj) {
                formData.append(key, dataObj[key]);
            }
            const response = await RequestAPI(apiPostHomeSliderWorks(formData));
            if (response.status === 200 || response.status === 201) {
                message.success("Success delete slide");
                setLoading(false);
                fetchHomeWorks(filter);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleDelete = (id) => {
        confirm({
            title: "Are you sure want to delete this slide?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let newDataObj = {
                    id,
                    status: 'Trash',
                };
                handleDeleteSlide(newDataObj);
            },
            onCancel() { return }
        });
    };

    const handleClick = (id) => {
        if (id) {
            window.location.href = process.env.REACT_APP_BASE_URL + "/home/slider/" + id;
        } else {
            window.location.href = process.env.REACT_APP_BASE_URL + "/home/slider";
        }
    };

    const handleQuickChange = (e, inputName) => {
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newQuickObj = Object.assign({}, quickChange);

        newQuickObj[name] = value;

        setQuickChange(newQuickObj);
        setErrorQuick('');
    };

    const handleQuickSubmit = async () => {
        setTableLoad(true);
        try {
            let formData = new FormData();
            formData.append('id', quickChange.id);
            formData.append(quickChange.changeData, quickChange.value);
            const response = await RequestAPI(apiPostHomeSliderWorks(formData));
            if (response.status === 200 || response.status === 201) {
                message.success("Success update data");
                setQuickChange({
                    id: '',
                    value: '',
                    changeData: '',
                });
                fetchHomeWorks(filter);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setTableLoad(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setTableLoad(false);
        }
    };

    const handleQuickData = (e) => {
        e.preventDefault();
        let isError = false;
        let errMsg = '';
        for (let key in quickChange) {
            if (quickChange['changeData'] === "sequence" && !validateNumber(quickChange['value'])) {
                errMsg = "Numeric input only";
                isError = true;
            }
            
            if (!quickChange[key] || validateEmptySpace(quickChange[key])) {
                errMsg = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleQuickSubmit();
        } else {
            setErrorQuick(errMsg);
        }
    };

    const resetForm = () => {
        setQuickChange({
            id: '',
            value: '',
            changeData: '',
        });
        setErrorQuick('');
    };

    const columns = [
        {
            name: 'Title',
            selector: 'small_title',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Sequence',
            selector: 'sequence',
            sortable: true,
            searchable: true,
            cell: (record) => {
                if (record.id === quickChange.id && quickChange.changeData === "sequence") {
                    return (
                        <div style={{ position: 'relative' }}>
                            <form onSubmit={(e) => handleQuickData(e)}>
                                <Input
                                    name="value"
                                    value={quickChange.value}
                                    onChange={(e) => handleQuickChange(e)}
                                />
                                <div
                                    className="form__check"
                                    onClick={(e) => handleQuickData(e)}
                                >
                                    <CheckOutlined />
                                </div>
                                <div
                                    className="form__close"
                                    onClick={() => resetForm()}
                                >
                                    <CloseOutlined />
                                </div>
                                <div className="form__field--error">
                                    {errorQuick}
                                </div>
                            </form>
                        </div>
                    )
                } else {
                    return (
                        <Tooltip placement="right" title={'double click to change'}>
                            <div 
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onDoubleClick={() => {
                                    let newQuickObj = Object.assign({}, quickChange);
                                    newQuickObj.id = record.id;
                                    newQuickObj.value = record.sequence;
                                    newQuickObj.changeData = "sequence";
                                    setQuickChange(newQuickObj);
                                }}>
                                {record.sequence}
                            </div>
                        </Tooltip>
                    )
                }
            },
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            searchable: true,
            cell: (record) => {
                if (record.id === quickChange.id && quickChange.changeData === "status") {
                    return (
                        <div style={{ position: 'relative' }}>
                            <form onSubmit={(e) => handleQuickData(e)}>
                                <Select
                                    name="value"
                                    value={quickChange.value}
                                    style={{ width: '150px' }}
                                    onChange={(e) => handleQuickChange(e, 'value')}
                                >
                                    {
                                        statusOpt.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.value}>{item.label}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                <div
                                    className="form__check"
                                    onClick={(e) => handleQuickData(e)}
                                >
                                    <CheckOutlined />
                                </div>
                                <div
                                    className="form__close"
                                    onClick={() => resetForm()}
                                >
                                    <CloseOutlined />
                                </div>
                                <div className="form__field--error">
                                    {errorQuick}
                                </div>
                            </form>
                        </div>
                    )
                } else {
                    return (
                        <Tooltip placement="right" title={'double click to change'}>
                            <div 
                                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                onDoubleClick={() => {
                                    let newQuickObj = Object.assign({}, quickChange);
                                    newQuickObj.id = record.id;
                                    newQuickObj.value = record.status;
                                    newQuickObj.changeData = "status";
                                    setQuickChange(newQuickObj);
                                }}>
                                {record.status}
                            </div>
                        </Tooltip>
                    )
                }
            },
        },
        {
            name: 'Action',
            width: '200px',
            button: true,
            cell: (record) => {
                return (
                    <Fragment>
                        <Button type="primary" onClick={() => handleClick(record.id)}>Edit</Button>
                        <Button type="danger" style={{ marginLeft: '8px' }} onClick={() => handleDelete(record.id)}>Delete</Button>
                    </Fragment>
                )
            },
        },
    ];

    const handlePerRowsChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['length'] = e;

        fetchHomeWorks(newFilterObj);
    };

    const handlePageChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['start'] = (e * newFilterObj.length) - newFilterObj.length;

        fetchHomeWorks(newFilterObj);
    };

    const handleSort = (e, dir) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['order'] = [
            {
                column: e.selector,
                dir: dir,
            }
        ];

        fetchHomeWorks(newFilterObj);
    };

    const handleFilter = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: search,
        };

        fetchHomeWorks(newFilterObj);
    };

    const handleReset = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: '',
        };

        fetchHomeWorks(newFilterObj);
        setSearch('');
    };

    return (
        <Fragment>
            <div className="home__filter">
                <Row align="bottom">
                    {/* <Col span={4}>
                        <div className="form__field" style={{ margin: '0', paddingRight: '8px' }}>
                            <div className="form__field-label">Filter by</div>
                            <Select>
                                <Option value={''}>All</Option>
                                {
                                    columns.map((item, index) => {
                                        if (!item.button) {
                                            return (
                                                <Option key={index} value={item.selector}>{item.name}</Option>
                                            )
                                        }
                                    })
                                }
                            </Select>
                        </div>
                    </Col> */}
                    <Col span={6}>
                        <div className="form__field" style={{ margin: '0', padding: '0 8px' }}>
                            <div className="form__field-label">Search</div>
                            <Input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                        <Button className="button--full-width" type="primary" onClick={() => handleFilter()}>
                            Apply Filter
                        </Button>
                    </Col>
                    <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                        <Button className="button--full-width" type="danger" onClick={() => handleReset()}>
                            Reset Filter
                        </Button>
                    </Col>
                    <Col offset={9} span={3} style={{ padding: '0 8px' }}>
                        <Button className="button--full-width" type="primary" onClick={() => handleClick()}>
                            Add New +
                        </Button>
                    </Col>
                </Row>
            </div>
            <DataTable
                columns={columns}
                data={data}
                progressPending={tableLoad}
                pagination
                paginationServer
                paginationTotalRows={totalRow}
                onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                onChangePage={(e) => handlePageChange(e)}
                onSort={(e, dir) => handleSort(e, dir)}
                sortServer
            />
        </Fragment>
    );
};

export default WorkSlider;