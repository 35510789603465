import {
    Button,
} from 'antd';

const NotFound = () => {
    return (
        <div className="login__container">
            <div className="notfound">
                <div className="notfound__title">
                    Oops..
                </div>
                <div className="notfound__desc">
                    Apakah anda tersesat?
                </div>
                <Button className="button" type="primary">
                    <a href={process.env.REACT_APP_BASE_URL}>
                        <span>Kembali ke halaman utama</span>
                    </a>
                </Button>
            </div>
        </div>
    )
};

export default NotFound;