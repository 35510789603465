import { Fragment, useEffect, useState } from 'react';
import {
    Alert,
    Row,
    Col,
    Button,
    Input,
    message,
} from 'antd';
import {
    apiGetContactDetail,
    apiPostContactDetail,
} from "../../../api/contact";
import {
    // validateEmail,
    validateEmptySpace,
} from '../../../utils/validations';
import { RequestAPI } from '../../../utils/requestApi';

const { TextArea } = Input;

const ContactContent = (props) => {
    const [data, setData] = useState({
        page_title: '',
        description: '',
        whatsapp_text: '',
        map: '',
        email: '',
        address: '',
        button_contact_text: '',
        hiring_text: '',
        hiring_link_text: '',
        address_name: '',
        seo_meta_title: '',
        seo_meta_description: '',
    });
    const [error, setError] = useState({
        page_title: '',
        description: '',
        whatsapp_text: '',
        map: '',
        email: '',
        address: '',
        button_contact_text: '',
        hiring_text: '',
        hiring_link_text: '',
        address_name: '',
        seo_meta_title: '',
        seo_meta_description: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        type: '',
        title: '',
        message: '',
    });
    const {
        reFetch,
        setReFetch,
        setLoading,
    } = props;

    const fetchContactDetail = async () => {
        setLoading(true);
        try {
            const response = await RequestAPI(apiGetContactDetail());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newDataObj = Object.assign({}, data);
                for (let key in newDataObj) {
                    if (key === "whatsapp_text") {
                        newDataObj[key] = resData['wa'];
                    } else {
                        newDataObj[key] = resData[key];
                    }
                };
                setData(newDataObj);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleReset = () => {
        let newErrorObj = Object.assign({}, error);
        for (let k in newErrorObj) {
            newErrorObj[k] = '';
        }
        
        fetchContactDetail();
        setError(newErrorObj);
        setShowAlert(false);
    };

    useEffect(() => {
        if (reFetch) {
            setShowAlert(false);
            setReFetch(false);
            handleReset();
        }
    // eslint-disable-next-line
    },[reFetch]);

    const handleChange = (e, inputName) => {
        setShowAlert(false);
        const name = e.target ? e.target.name : inputName;
        const value = e.target ? e.target.value : e;
        let newDataObj = Object.assign({}, data);
        let newErrorObj = Object.assign({}, error);

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setData(newDataObj);
        setError(newErrorObj);
    };

    const handleDataContactDetail = async () => {
        setLoading(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        try {
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            };
            const response = await RequestAPI(apiPostContactDetail(formData));
            if (response.status === 200 || response.status === 201) {
                setAlert({
                    type: 'success',
                    title: 'Success',
                    message: 'Success update contact detail',
                });
                setShowAlert(true);
                fetchContactDetail();
            } else {
                const errMsg = response.error.message;
                setAlert({
                    type: 'error',
                    title: 'Error Contact Detail',
                    message: errMsg,
                });
                setShowAlert(true);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            setAlert({
                type: 'error',
                title: 'Error Contact Detail',
                message: errMsg,
            });
            setShowAlert(true);
            setLoading(false);
        }
    };

    const handleSubmit = () => {
        let isError = false;
        let newErrorObj = Object.assign({}, error);
        for (let key in data) {
            if (!data[key] || validateEmptySpace(data[key])) {
                newErrorObj[key] = "Field is empty";
                isError = true;
            }
        }

        if (!isError) {
            handleDataContactDetail();
        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
            setError(newErrorObj);
            setAlert({
                type: 'error',
                title: 'Error Contact Detail',
                message: "There's an error on input field",
            });
            setShowAlert(true);
        }
    };

    return (
        <Fragment>
            <div className="form__wrapper">
                {
                    showAlert && (
                        <Alert 
                            style={{ marginBottom: '16px', textAlign: 'left' }}
                            type={alert.type}
                            message={alert.title}
                            description={alert.message} 
                            showIcon
                        />
                    )
                }
                <div className="form__field">
                    <div className="form__field-label">Title</div>
                    <Input
                        name="page_title"
                        value={data.page_title}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.page_title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Description</div>
                    <TextArea
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        name="description"
                        value={data.description}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.description}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Whatsapp Text</div>
                    <Input
                        name="whatsapp_text"
                        value={data.whatsapp_text}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.whatsapp_text}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Button Contact Text</div>
                    <Input
                        name="button_contact_text"
                        value={data.button_contact_text}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.button_contact_text}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Hiring Text</div>
                    <Input
                        name="hiring_text"
                        value={data.hiring_text}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.hiring_text}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Hiring Link Text</div>
                    <Input
                        name="hiring_link_text"
                        value={data.hiring_link_text}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.hiring_link_text}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Hiring Link URL</div>
                    <Input
                        name="email"
                        value={data.email}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.email}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Maps URL</div>
                    <Input
                        name="map"
                        value={data.map}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.map}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Location Name</div>
                    <Input
                        name="address_name"
                        value={data.address_name}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.address_name}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">Location Address</div>
                    <TextArea 
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        name="address"
                        value={data.address}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.address}
                    </div>
                </div>
                <hr/>
                <div className="form__field">
                    <div className="form__field-label">SEO - Meta Title</div>
                    <Input
                        name="seo_meta_title"
                        value={data.seo_meta_title}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.seo_meta_title}
                    </div>
                </div>
                <div className="form__field">
                    <div className="form__field-label">SEO - Meta Description</div>
                    <Input
                        name="seo_meta_description"
                        value={data.seo_meta_description}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="form__field--error">
                        {error.seo_meta_description}
                    </div>
                </div>
                <div className="form__button">
                    <Row>
                        <Col span={3}>
                            <Button className="button" type="primary" onClick={() => handleSubmit()}>
                                Update
                            </Button>
                        </Col>
                        <Col offset={2} span={3}>
                            <Button className="button" type="danger" onClick={() => handleReset()}>
                                Reset Form
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default ContactContent;