import { Fragment, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button,
    Input,
    Breadcrumb,
    Modal,
    message,
} from 'antd';
import {
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import DataTable from 'react-data-table-component';
import {
    apiGetRoleList,
    apiPostRole,
} from "../../api/role";
import CMSLayout from '../../components/layout/cms';
import { RequestAPI } from '../../utils/requestApi';

const { confirm } = Modal;

const Role = (props) => {
    const [getData, setGetData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [tableLoad, setTableLoad] = useState(false);
    const [data, setData] = useState([]);
    const [totalRow, setTotalRow] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
        columns: [],
        order: [],
        start: 0,
        length: 10,
        search: {
            value: ''
        },
    });

    const fetchRoleList = async (filterData) => {
        setTableLoad(true);
        try {
            const response = await RequestAPI(apiGetRoleList(filterData));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data.data;
                const resDataTotal = response.data.data.recordsTotal;
                let newFilterObj = Object.assign({}, filterData);
                newFilterObj['columns'] = [];
                columns.map((item) => {
                    let columnData =  {
                        data: "",
                        searchable: "true",
                        search: {
                            value: ""
                        }
                    };
                    if (item.searchable) {
                        columnData.data = item.selector;
                        newFilterObj['columns'].push(columnData);
                    }
                    return columnData;
                });
                setTotalRow(resDataTotal);
                setTableLoad(false);
                setFilter(newFilterObj);
                setData(resData);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setTableLoad(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setTableLoad(false);
        }
    };

    useEffect(() => {
        if (getData) {
            fetchRoleList(filter);
            setGetData(false);
        };
    // eslint-disable-next-line
    },[getData]);

    const handleClick = (id) => {
        if (id) {
            window.location.href = process.env.REACT_APP_BASE_URL + "/account/role/edit/" + id;
        } else {
            window.location.href = process.env.REACT_APP_BASE_URL + "/account/role/new";
        }
    };

    const handleDeleteData = async (form) => {
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('id', form.id);
            formData.append(form.changeData, form.value);
            const response = await RequestAPI(apiPostRole(formData));
            if (response.status === 200 || response.status === 201) {
                message.success("Success delete data");
                fetchRoleList(filter);
                setLoading(false);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleDelete = (record) => {
        confirm({
            title: "Are you sure want to delete this role?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let newDataObj = {
                    id: record.id,
                    changeData: 'status',
                    value: 'Trash',
                };
                handleDeleteData(newDataObj);
            },
            onCancel() { return }
        });
    };

    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            searchable: true,
        },
        {
            name: 'Action',
            width: '200px',
            button: true,
            cell: (record) => {
                return (
                    <Fragment>
                        <Button type="primary" onClick={() => handleClick(record.id)}>Edit</Button>
                        <Button type="danger" style={{ marginLeft: '8px' }} onClick={() => handleDelete(record)}>Delete</Button>
                    </Fragment>
                )
            },
        },
    ];

    const handlePerRowsChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['length'] = e;

        fetchRoleList(newFilterObj);
    };

    const handlePageChange = (e) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['start'] = (e * newFilterObj.length) - newFilterObj.length;

        fetchRoleList(newFilterObj);
    };

    const handleSort = (e, dir) => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['order'] = [
            {
                column: e.selector,
                dir: dir,
            }
        ];

        fetchRoleList(newFilterObj);
    };

    const handleFilter = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: search,
        };

        fetchRoleList(newFilterObj);
    };

    const handleReset = () => {
        let newFilterObj = Object.assign({}, filter);
        newFilterObj['search'] = {
            value: '',
        };

        fetchRoleList(newFilterObj);
        setSearch('');
    };

    return (
        <CMSLayout loading={loading} ctx={props}>
            <div style={{ marginBottom: '16px' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>Account - Role</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h1>
                Role List
            </h1>
            <div className="cms__container">
                <div className="home__filter">
                    <Row align="bottom">
                        <Col span={6}>
                            <div className="form__field" style={{ margin: '0', padding: '0 8px' }}>
                                <div className="form__field-label">Search by</div>
                                <Input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                            <Button className="button--full-width" type="primary" onClick={() => handleFilter()}>
                                Apply Filter
                            </Button>
                        </Col>
                        <Col span={3} style={{ margin: '0', padding: '0 8px' }}>
                            <Button className="button--full-width" type="danger" onClick={() => handleReset()}>
                                Reset Filter
                            </Button>
                        </Col>
                        <Col offset={9} span={3} style={{ padding: '0 8px' }}>
                            <Button className="button--full-width" type="primary" onClick={() => handleClick()}>
                                Add New +
                            </Button>
                        </Col>
                    </Row>
                </div>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={tableLoad}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRow}
                    onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                    onChangePage={(e) => handlePageChange(e)}
                    onSort={(e, dir) => handleSort(e, dir)}
                    sortServer
                />
            </div>
        </CMSLayout>
    );
};

export default Role;