const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetBlogsList = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/blog/get-blog`,
    isAuthorize: true,
});

export const apiGetBlogsText = () => ({
    method: 'POST',
    url: `${getBaseUrl}/blog/get-blog-copytexts`,
    isAuthorize: true,
});

export const apiGetBlogsDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/blog/get-blog-detail`,
    isAuthorize: true,
});

export const apiGetBlogsCategories = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/blog/get-blog-categories`,
    isAuthorize: true,
});

export const apiPostBlogsText = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/blog/insert-blog-copytexts`,
    isAuthorize: true,
});

export const apiPostBlogsDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/blog/insert-blog`,
    isAuthorize: true,
});