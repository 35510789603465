const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetWorksList = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/works/get-works`,
    isAuthorize: true,
});

export const apiGetWorksDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/works/get-works-detail`,
    isAuthorize: true,
});

export const apiGetWorksImageList = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/works/get-works-image`,
    isAuthorize: true,
});

export const apiGetWorksText = () => ({
    method: 'POST',
    url: `${getBaseUrl}/works/get-works-copytexts`,
    isAuthorize: true,
});

export const apiGetWorksCategories = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/works/get-works-categories`,
    isAuthorize: true,
});

export const apiPostWorksDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/works/insert-works`,
    isAuthorize: true,
});

export const apiPostWorksImage = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/works/insert-works-image`,
    isAuthorize: true,
});

export const apiPostWorksText = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/works/insert-works-copytexts`,
    isAuthorize: true,
});