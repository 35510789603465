import { useEffect, useState } from 'react';
import {
    Layout,
    Menu,
    Spin,
    message,
} from 'antd';
import {
    HomeOutlined,
    BookOutlined,
    ContactsOutlined,
    CommentOutlined,
    FileTextOutlined,
    DatabaseOutlined,
    OrderedListOutlined,
    AuditOutlined,
    UserOutlined,
    TeamOutlined,
    SettingOutlined,
    ShareAltOutlined,
    LogoutOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import { hasSubmenu } from '../../../utils/variablesObj';
import Logo from '../../../assets/images/logo-color.svg';

const { Header, Footer, Sider, Content } = Layout;
const { SubMenu } = Menu;

const CMSLayout = (props) => {
    const [getData, setGetData] = useState(true);
    const [activeSubMenu, setActiveSubMenu] = useState([]);
    const [activeMenu, setActiveMenu] = useState('');
    const {
        children,
        ctx,
        loading,
    } = props;
    const getAccess = localStorage.getItem('access');
    const accessJSON = JSON.parse(getAccess);

    useEffect(() => {
        if (getData) {
            const url = ctx.match.path;
            const urlText = url.split("/");
            if (hasSubmenu.includes(urlText[1])) {
                setActiveSubMenu([urlText[1]]);
                setActiveMenu(urlText[1] + '/' +urlText[2]);
            } else {
                setActiveMenu(urlText[1]);
            }
            setGetData(false);
        }
    // eslint-disable-next-line
    }, [getData, ctx]);

    const handleClick = (e) => {
        if (e.key !== "logout") {
            window.location.href = process.env.REACT_APP_BASE_URL + "/" + e.key;
        } else {
            message.success("Success logout");
            localStorage.clear();
            setTimeout(() => { 
                window.location.href = process.env.REACT_APP_BASE_URL + "/login";
            }, 500);
        }
    };

    return (
        <Layout className="cms-layout">
            {
                loading && (
                    <div className="cms-layout__loading">
                        <div className="cms-layout__spinner">
                            <Spin/>
                        </div>
                    </div>
                )
            }
            <Sider className="cms-layout__sider">
                <div className="cms-layout__sider-wrapper">
                    <div className="cms-layout__logo">
                        <img src={Logo} alt="logo"/>
                    </div>
                    <Menu
                        className="cms-layout__menu"
                        onClick={(e) => handleClick(e)}
                        defaultSelectedKeys={['home']}
                        selectedKeys={[activeMenu]}
                        openKeys={activeSubMenu}
                        onOpenChange={(keys) => setActiveSubMenu(keys)}
                        mode="inline"
                    >
                        {
                            accessJSON.map((item) => {
                                if (item.name === "Home") {
                                    return (
                                        <Menu.Item className="cms-layout__menu-item" icon={<HomeOutlined />} key="home">
                                            Home
                                        </Menu.Item>
                                    )
                                } else if (item.name === "About") {
                                    return (
                                        <Menu.Item className="cms-layout__menu-item" icon={<ContactsOutlined />} key="about">
                                            About
                                        </Menu.Item>
                                    )
                                } else if (item.name === "Works") {
                                    return (
                                        <Menu.Item className="cms-layout__menu-item" icon={<BookOutlined />} key="works">
                                            Works
                                        </Menu.Item>
                                    )
                                } else if (item.name === "Blogs") {
                                    return (
                                        <Menu.Item className="cms-layout__menu-item" icon={<FileTextOutlined />} key="blogs">
                                            Blogs
                                        </Menu.Item>
                                    )
                                } else if (item.name === "Contact") {
                                    return (
                                        <Menu.Item className="cms-layout__menu-item" icon={<CommentOutlined />} key="contact">
                                            Contact
                                        </Menu.Item>
                                    )
                                } else if (item.name === "Master Data") {
                                    return (
                                        <SubMenu className="cms-layout__menu-item" key="master" icon={<DatabaseOutlined />} title="Master Data">
                                            <Menu.Item key="master/category" icon={<OrderedListOutlined />}>Category</Menu.Item>
                                        </SubMenu>
                                    )
                                } else if (item.name === "Account") {
                                    return (
                                        <SubMenu className="cms-layout__menu-item" key="account" icon={<TeamOutlined />} title="Account">
                                            <Menu.Item key="account/user" icon={<UserOutlined />}>Users</Menu.Item>
                                            <Menu.Item key="account/role" icon={<AuditOutlined />}>Roles</Menu.Item>
                                        </SubMenu>
                                    )
                                } else if (item.name === "Setting") {
                                    return (
                                        <SubMenu className="cms-layout__menu-item" key="setting" icon={<SettingOutlined />} title="Settings">
                                            <Menu.Item key="setting/general" icon={<ToolOutlined />}>General Settings</Menu.Item>
                                            <Menu.Item key="setting/social-media" icon={<ShareAltOutlined />}>Social Media</Menu.Item>
                                        </SubMenu>
                                    )
                                }
                                return '';
                            })
                        }
                        <Menu.Item className="cms-layout__menu-item" icon={<LogoutOutlined />} key="logout">
                            Logout
                        </Menu.Item>
                    </Menu>
                </div>
            </Sider>
            <Layout>
                <Header className="cms-layout__header">
                    <h1>XG Website CMS</h1>
                </Header>
                <Content className="cms-layout__content">
                    {children}
                </Content>
                <Footer className="cms-layout__footer">
                    &#169; 2021 Xtreme Graphics all rights reserved.
                </Footer>
            </Layout>
        </Layout>
    );
};

export default CMSLayout;