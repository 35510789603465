const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetHomeSliderWorks = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/home/get-works-slider`,
    isAuthorize: true,
});

export const apiGetHomeSliderWorksDetail = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/home/get-works-slider-detail`,
    isAuthorize: true,
});

export const apiPostHomeSliderWorks = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/home/insert-works-slider`,
    isAuthorize: true,
});

export const apiGetHomeSliderAbout = () => ({
    method: 'POST',
    url: `${getBaseUrl}/home/get-about-slider`,
    isAuthorize: true,
});

export const apiPostHomeSliderAbout = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/home/insert-about-slider`,
    isAuthorize: true,
});

export const apiGetHomeSliderContact = () => ({
    method: 'POST',
    url: `${getBaseUrl}/home/get-contact-slider`,
    isAuthorize: true,
});

export const apiPostHomeSliderContact = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/home/insert-contact-slider`,
    isAuthorize: true,
});

export const apiGetHomeSliderSEO = () => ({
    method: 'POST',
    url: `${getBaseUrl}/home/get-home-seo`,
    isAuthorize: true,
});

export const apiPostHomeSliderSEO = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/home/insert-home-seo`,
    isAuthorize: true,
});